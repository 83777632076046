import { actionTypes, reducer as formReducer } from 'redux-form';

import type { RecurrenceScheduleInput } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { pickByPaths } from '~/utils';

export function createFormsReducer(): (...args: Array<any>) => any {
  const reducer = formReducer.plugin({
    disclosures: (state, action) => {
      switch (action.type) {
        case ACTIONS.CLICKED_NO_DISCLOSURES_APPLY:
          return {
            ...state,
            values: {
              exchangeOrFinraAffiliationDisclosure: {
                isAffiliated: false,
              },
              controlPersonDisclosure: {
                isControlPerson: false,
              },
              politicalExposureDisclosure: {
                isPoliticallyExposed: false,
              },
              backupWithholding: {
                isSubjectToBackupWithholding: false,
              },
            },
          };
        default:
          return state;
      }
    },
    // @ts-expect-error - TS2322 - Type '(state: any, action: any, prevState: any) => any' is not assignable to type 'Reducer<any, AnyAction>'.
    'transfer-instance': transferReducer,
    // @ts-expect-error - TS2322 - Type '(state: any, action: any, prevState: any) => any' is not assignable to type 'Reducer<any, AnyAction>'.
    'edit-transfer-schedule': transferReducer,
    // @ts-expect-error - TS2322 - Type '(state: any, action: any, prevState: any) => any' is not assignable to type 'Reducer<any, AnyAction>'.
    'savings-initial-deposit': transferReducer,
  });

  return (state, action) => {
    const baseState = reducer(state, action);
    if (action.type === actionTypes.UNREGISTER_FIELD) {
      const formState = baseState[action.meta.form];
      return {
        ...baseState,
        [action.meta.form]: destroyUnregistered(formState),
      };
    }
    return baseState;
  };
}

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type. | TS7006 - Parameter 'prevState' implicitly has an 'any' type.
function transferReducer(state, action, prevState) {
  switch (action.type) {
    case actionTypes.CHANGE:
      // Check to see if toParticipantId/fromParticipantId fields have
      // both been filled out, then clear the opposite field on value change
      // so users can't select invalid transfer participants
      if (
        prevState?.values?.toParticipantId &&
        prevState.values.fromParticipantId
      ) {
        // Prevent manipulation of to/from participantIds
        if (
          action.meta.form === 'savings-initial-deposit' &&
          action.meta.field === 'fromParticipantId'
        ) {
          return state;
        }
      }

      // if a new schedule is found, clear the old frequency if one exists.
      if (
        prevState?.values?.schedule &&
        state?.values?.schedule &&
        prevState.values.schedule !== state.values.schedule
      ) {
        const frequencyToClear = getRedundantScheduleKey(
          prevState.values.schedule,
          state.values.schedule,
        );
        if (frequencyToClear) {
          return {
            ...state,
            values: {
              ...state.values,
              schedule: {
                ...state.values.schedule,
                [frequencyToClear]: null,
              },
            },
          };
        }
      }

      return state;
    default:
      return state;
  }
}

function getRedundantScheduleKey(
  prevSchedule: RecurrenceScheduleInput,
  schedule: RecurrenceScheduleInput,
): string | null | undefined {
  const keys = Object.keys(prevSchedule);
  for (let i = 0; i < keys.length; i++) {
    if (
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RecurrenceScheduleInput'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RecurrenceScheduleInput'.
      prevSchedule[keys[i]] === schedule[keys[i]] &&
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RecurrenceScheduleInput'.
      prevSchedule[keys[i]] !== null &&
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RecurrenceScheduleInput'.
      schedule[keys[i]] !== null
    ) {
      return keys[i];
    }
  }

  return null;
}

function destroyUnregistered(state: Record<string, any>): Record<string, any> {
  const { fields, registeredFields, values, ...rest } = state;
  return {
    ...rest,
    fields: pickByPaths(fields, Object.keys(registeredFields || {})),
    registeredFields,
    values: pickByPaths(values, Object.keys(registeredFields || {})),
  };
}
