import type { SavingsAccountInvitationFormValues } from '~/flows/components/onboarding/savings/components/SavingsAccountInvitationForm';

export const SAVINGS_ONBOARDING_ACTIONS = Object.freeze({
  SET_TERMS_CONDITIONS_SIGNATURE: 'SET_TERMS_CONDITIONS_SIGNATURE' as const,
  SET_JOINT_ACCOUNT_INVITEE: 'SET_JOINT_ACCOUNT_INVITEE' as const,
  SET_FULLY_PAID_LENDING_STATUS: 'SET_FULLY_PAID_LENDING_STATUS' as const,
});

export type CreateSavingsTermsAndConditionsAction = {
  payload: string | undefined;
  type: typeof SAVINGS_ONBOARDING_ACTIONS.SET_TERMS_CONDITIONS_SIGNATURE;
};

export type JointAccountInviteeInformationAction = {
  payload: SavingsAccountInvitationFormValues;
  type: typeof SAVINGS_ONBOARDING_ACTIONS.SET_JOINT_ACCOUNT_INVITEE;
};

export type FullyPaidLendingStatus = {
  payload: boolean;
  type: typeof SAVINGS_ONBOARDING_ACTIONS.SET_FULLY_PAID_LENDING_STATUS;
};

export type SavingsOnboardingAction =
  | CreateSavingsTermsAndConditionsAction
  | JointAccountInviteeInformationAction
  | FullyPaidLendingStatus;
