import * as React from 'react';

import { useLocation } from '~/hooks/useLocation';
import {
  addSlicesToPieOrganizerAction,
  confirmedPieOrganizerExit,
  initializePieOrganizerAction,
  type PieOrganizerEvent,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { PortfolioOrganizer } from './PortfolioOrganizerComponent';

export const PortfolioOrganizerPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isCrypto, setIsCrypto] = React.useState(false);
  const event: PieOrganizerEvent | undefined = location.state?.event;

  React.useEffect(() => {
    if (event) {
      if (event.type === 'ADDING_SLICES') {
        dispatch(
          addSlicesToPieOrganizerAction({
            legacyIds: event.legacyIds,
            sliceableIds: event.sliceableIds,
          }),
        );
      } else {
        dispatch(initializePieOrganizerAction(event.initConfig));

        // If we're handed an onInit callback, call that
        if (event.initConfig.onInit) {
          event.initConfig.onInit();
        }

        setIsCrypto(event.initConfig.isCrypto ?? false);
      }
    } else {
      // if no event is passed, do nothing so that we rely on our existing pie organizer state
    }

    return () => {
      // we check the pathname from the `window.location` because `useLocation` doesn't trigger when this component
      // isn't mounted at the route level. `window.location.pathname` is always up to date.
      const isStillEditing =
        /^\/d\/(c\/add-slices|invest\/portfolio-(editor|organizer))/.test(
          window.location.pathname,
        );
      if (!isStillEditing) {
        dispatch(confirmedPieOrganizerExit());
      }
    };
  }, [event, dispatch]);
  return <PortfolioOrganizer isCrypto={isCrypto} />;
};
