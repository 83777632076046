import { Banner, Box, Button, HXS, PL, PXL, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { NavigableButton } from '~/components/NavigableButton';
import { useBorrowAccountRouteQuery } from '~/graphql/hooks';
import { useSupportLink } from '~/hooks/useSupportLink';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Spinner } from '~/toolbox/spinner';
import { formatNumber } from '~/utils';

type MarginCallInformationInfoScreenProps = {
  onFinishStep: () => void;
};

// Styled Components
const StyledContentContainer = styled(Box)`
  margin: auto;
  width: 582px;
`;

export const MarginCallInformationInfoScreenStep = ({
  onFinishStep,
}: MarginCallInformationInfoScreenProps) => {
  const borrowAccountId = useSelector(
    (state) => state.newFlows.MARGIN_CALL_INFORMATION.borrowAccountId,
  );
  const { data, loading } = useBorrowAccountRouteQuery({
    variables: {
      borrowAccountId: borrowAccountId as string,
    },
    skip: !borrowAccountId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spinner />;
  }

  if (data?.node?.__typename !== 'BorrowAccount') {
    return <GenericSystemError />;
  }

  const borrowAccount = data.node;
  const { investAccount, status } = borrowAccount;

  const marginCallMarginEquityDeficit =
    status?.maintenanceCallMarginEquityDeficit;
  const marginEquity = status?.marginEquity;
  const requiredMarginEquity = status?.requiredMarginEquity;
  const investAccountName = investAccount?.name;
  return (
    <StyledContentContainer>
      <Illustration
        // @ts-expect-error - TS2322 - Type '{ alt: string; height: number; mb: number; mt: number; name: "marginCall"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
        alt="Margin call"
        height={216}
        mb={66}
        mt={128}
        name="marginCall"
      />
      <HXS content="Margin call" mb={16} />
      <PL
        content="Your account value has dropped and your equity value is now below our agreed upon margin threshold. This can happen because of the following reasons: deprecating account value, market volatility, or changes to a security’s margin requirement."
        mb={16}
      />
      <PL mb={16}>
        For the account <b>{investAccountName}</b>, the required equity value is{' '}
        <b>
          {requiredMarginEquity ? formatNumber(requiredMarginEquity) : '$--'}
        </b>
        . The current equity value is{' '}
        <b>{marginEquity ? formatNumber(marginEquity) : '$--'}</b>. The margin
        call is{' '}
        <b>
          {marginCallMarginEquityDeficit
            ? formatNumber(marginCallMarginEquityDeficit)
            : '$--'}
        </b>
        .
      </PL>
      <PXL fontWeight={600} content="How do I resolve this?" />
      <PL
        content="In order to resolve your margin call, you will need to deposit additional funds or sell a portion of your portfolio to pay back the required amount."
        mb={32}
      />
      <Banner
        content="If the call is not satisfied, we will be required to sell securities in your account to make up the difference." // TODO update once we do Icons
        // icon={<Image alt="" alt="" src={alert} />}
        kind="alert"
        mb={64}
        size="inline"
      />
      <MarginCallButtonGroup handleResolveCallClick={() => onFinishStep()} />
    </StyledContentContainer>
  );
};

const MarginCallButtonGroup = ({
  handleResolveCallClick,
}: {
  handleResolveCallClick: () => void;
}) => {
  const supportLink = useSupportLink();
  return (
    <ButtonGroup mb={128}>
      <NavigableButton
        kind="secondary"
        label="Contact Support"
        size="large"
        {...supportLink}
      />
      <Button
        label="Resolve Call"
        kind="primary"
        onClick={handleResolveCallClick}
      />
    </ButtonGroup>
  );
};
