import { Box, Input, PXL } from '@m1/liquid-react';
import * as React from 'react';

import type { SmartTransferFundingTimePeriodEnum } from '~/graphql/types';
import { useAmountRangeValidation } from '~/hooks/useAmountRangeValidation';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';
import { Radio } from '~/toolbox/radio';
import { formatCurrencyWithCommas } from '~/utils/formatting';

import { DEFAULT_FULFILLMENT_CONDITION_FIELDS } from './constants';

type FundingPerPeriodProps = {
  fulfillmentConditionRequirements: any | null | undefined;
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onIsContinueAllowedChange: (arg0: boolean) => void;
  preSelectedFulfillmentConditionDataOfActiveContra:
    | SmartTransferFulfillmentConditionInput
    | null
    | undefined;
};

const getFundingPeriodStatusMessage = (
  fundingPeriod: SmartTransferFundingTimePeriodEnum | null | undefined,
): string => {
  switch (fundingPeriod) {
    case 'WEEK':
      return 'per week';
    case 'MONTH':
      return 'per month';
    case 'QUARTER':
      return 'per quarter';
    case 'YEAR':
      return 'per year';
    default:
      return '';
  }
};

export const FundingPerPeriod = ({
  fulfillmentConditionRequirements,
  onFulfillmentDataChange,
  onIsContinueAllowedChange,
  preSelectedFulfillmentConditionDataOfActiveContra,
}: FundingPerPeriodProps) => {
  const fundingPeriods = fulfillmentConditionRequirements?.fundingPeriods;
  const [fundingPeriodValue, setFundingPeriodValue] = React.useState(
    preSelectedFulfillmentConditionDataOfActiveContra?.fundingPeriod,
  );
  const defaultAmountValue =
    typeof preSelectedFulfillmentConditionDataOfActiveContra?.fundingAmount ===
    'number'
      ? `${preSelectedFulfillmentConditionDataOfActiveContra.fundingAmount}`
      : '';
  const [amountValueInitial, setAmountValue] =
    React.useState(defaultAmountValue);
  const { onInputChange, amountErrorMessage, amountValue } =
    // @ts-expect-error - TS2554 - Expected 5-7 arguments, but got 4.
    useAmountRangeValidation(
      amountValueInitial,
      setAmountValue,
      fulfillmentConditionRequirements?.minFundingAmount,
      fulfillmentConditionRequirements?.maxFundingAmount,
    );
  // fire parent callback to sync continue state
  React.useEffect(() => {
    onIsContinueAllowedChange(
      Boolean(
        Boolean(fundingPeriodValue) &&
          amountValue !== '' &&
          !amountErrorMessage,
      ),
    );
  }, [
    amountValue,
    amountErrorMessage,
    fundingPeriodValue,
    onIsContinueAllowedChange,
  ]);

  // when fulfillment condition data changes, fire callback
  React.useEffect(() => {
    onFulfillmentDataChange({
      ...DEFAULT_FULFILLMENT_CONDITION_FIELDS,
      fulfillmentConditionType: 'FUNDING_PER_PERIOD',
      fundingAmount: amountValue === '' ? null : Number(amountValue),
      fundingPeriod: fundingPeriodValue,
    });
  }, [amountValue, fundingPeriodValue, onFulfillmentDataChange]);
  return (
    <>
      <PXL fontWeight={600} content="Select time period" mb={16} />
      <Radio.Group
        onChange={(value) => {
          setFundingPeriodValue(value);
        }}
        value={fundingPeriodValue}
      >
        {fundingPeriods?.map((period: any, i: number) => {
          return (
            <Radio.Choice
              key={i}
              label={period.label}
              // @ts-expect-error - TS2769 - No overload matches this call.
              style={{
                margin: '12px 16px',
              }}
              value={period.value}
            />
          );
        })}
      </Radio.Group>
      <Box mt={16}>
        {fundingPeriodValue !== null && (
          <Input
            autoComplete="off"
            onChange={onInputChange}
            value={formatCurrencyWithCommas(amountValue)}
            error={amountErrorMessage}
            label="Enter amount"
            maskType="money"
            additionalContent={getFundingPeriodStatusMessage(
              fundingPeriodValue,
            )}
          />
        )}
      </Box>
    </>
  );
};
