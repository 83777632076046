import { Box, type LayoutableProps, Input } from '@m1/liquid-react';
import * as React from 'react';

import { formatNumber } from '~/utils';
import { formatCurrencyWithCommas } from '~/utils/formatting';
import { onlyNumbers } from '~/utils/normalizers';

type SmartTransferRefillBalanceProps = LayoutableProps & {
  balanceThresholdAmount: string;
  balanceTriggerEditRequirements: any;
  onInputDataChange: (arg0: {
    underBalanceRefillTargetAmount: string;
    underBalanceRefillTargetAmountErrorMessage: string;
  }) => void;
  preFilledAmount?: string;
};

export const SmartTransferRefillBalanceInput = ({
  balanceTriggerEditRequirements,
  balanceThresholdAmount,
  onInputDataChange,
  preFilledAmount,
  ...rest
}: SmartTransferRefillBalanceProps) => {
  const [underBalanceRefillTargetAmount, setUnderBalanceRefillTargetAmount] =
    React.useState(preFilledAmount || '');
  const underBalanceRefillTargetAmountErrorMessage = React.useMemo(() => {
    // if there is both is both an underBalanceRefillTarget and a balanceThreshold, check to see the former is less than the latter
    if (
      balanceThresholdAmount !== '' &&
      underBalanceRefillTargetAmount !== ''
    ) {
      if (
        Number(underBalanceRefillTargetAmount) < Number(balanceThresholdAmount)
      ) {
        return `Refill balance cannot be less than your first balance setting of $${balanceThresholdAmount}`;
      }
    }

    // if there is just an underBalanceRefillTarget amount, check the lens driven min/max values
    if (underBalanceRefillTargetAmount !== '') {
      if (
        typeof balanceTriggerEditRequirements.minBalanceTriggerThreshold ===
          'number' &&
        Number(underBalanceRefillTargetAmount) <
          Number(balanceTriggerEditRequirements.minBalanceTriggerThreshold)
      ) {
        return `The minimum amount is ${formatNumber(
          balanceTriggerEditRequirements.minBalanceTriggerThreshold,
        )}`;
      }
      if (
        typeof balanceTriggerEditRequirements.maxBalanceTriggerThreshold ===
          'number' &&
        Number(underBalanceRefillTargetAmount) >
          Number(balanceTriggerEditRequirements.maxBalanceTriggerThreshold)
      ) {
        return `The maximum amount is ${formatNumber(
          balanceTriggerEditRequirements.maxBalanceTriggerThreshold,
        )}`;
      }
    }
    return '';
  }, [
    balanceThresholdAmount,
    balanceTriggerEditRequirements.minBalanceTriggerThreshold,
    balanceTriggerEditRequirements.maxBalanceTriggerThreshold,
    underBalanceRefillTargetAmount,
  ]);

  const onChangeCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // format to number
      const formattedValue = onlyNumbers(event.target.value);
      setUnderBalanceRefillTargetAmount(formattedValue);
    },
    [],
  );

  // when state updates, let fire parent callback
  React.useEffect(() => {
    onInputDataChange({
      underBalanceRefillTargetAmount: underBalanceRefillTargetAmount,
      underBalanceRefillTargetAmountErrorMessage:
        underBalanceRefillTargetAmountErrorMessage,
    });
  }, [
    underBalanceRefillTargetAmount,
    underBalanceRefillTargetAmountErrorMessage,
    onInputDataChange,
  ]);
  return (
    <Box>
      <Input
        {...rest}
        autoComplete="off"
        onChange={onChangeCallback}
        value={formatCurrencyWithCommas(underBalanceRefillTargetAmount)}
        error={underBalanceRefillTargetAmountErrorMessage}
        label="Enter amount"
        maskType="money"
      />
    </Box>
  );
};
