import React from 'react';

import type { SecurityDetailFragment } from '~/graphql/types';
import { formatCurrencyOrNull } from '~/utils';

import { MissingSecurityData } from './MissingSecurityData';
import { SecurityDataContainer } from './SecurityDataContainer';
import { SecurityDataRow } from './SecurityDataRow';

type SecurityPriceDataProps = {
  security: SecurityDetailFragment;
};

const formatRangeCurrency = (
  low: number | null | undefined,
  high: number | null | undefined,
): string | null | undefined => {
  return typeof low === 'number' && typeof high === 'number'
    ? `$${low} - $${high}`
    : null;
};

export const SecurityPriceData = ({ security }: SecurityPriceDataProps) => {
  const { fundamentals, quote } = security;

  const fundamentalsNull =
    !fundamentals ||
    Object.values(fundamentals).every((value) => value === null);
  const quoteNull =
    !quote || Object.values(quote).every((value) => value === null);

  if (fundamentalsNull && quoteNull) {
    return (
      <SecurityDataContainer
        width={380}
        headingContent="Price"
        flexDirection="column"
      >
        <MissingSecurityData title="No price data" />
      </SecurityDataContainer>
    );
  }

  const isCrypto = security.__typename === 'CryptoAsset';

  const week52Low = isCrypto
    ? security.week52Low
    : security.fundamentals?.week52LowPrice;
  const week52High = isCrypto
    ? security.week52High
    : security.fundamentals?.week52HighPrice;

  const week52Range = formatRangeCurrency(week52Low, week52High);
  const dayRange = formatRangeCurrency(quote?.lowPrice, quote?.highPrice);

  const containerProps = isCrypto
    ? ({
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      } as const)
    : ({
        flexDirection: 'column',
        maxWidth: 380,
      } as const);

  return (
    <SecurityDataContainer headingContent="Price" {...containerProps}>
      <SecurityDataRow
        headingContent="Previous close"
        value={formatCurrencyOrNull(security.quote?.previousClosePrice)}
      />
      <SecurityDataRow
        headingContent="Today's open"
        value={formatCurrencyOrNull(security.quote?.openPrice)}
      />
      <SecurityDataRow headingContent="Day's range" value={dayRange} />
      <SecurityDataRow headingContent="52 week range" value={week52Range} />
    </SecurityDataContainer>
  );
};
