import React from 'react';

import { SliceableNameCell } from '~/components/pie';
import type { WatchlistItemFragment } from '~/graphql/types';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Table } from '~/toolbox/table';
import { formatNumber, isNotNil } from '~/utils';

import { WatchlistLatestQuoteChange } from './WatchlistLatestQuoteChange';

type WatchlistItemRowProps = {
  item: WatchlistItemFragment;
  index: number;
  amountToDisplay: number | null | undefined;
};

export const WatchlistItemRow = ({
  item,
  index,
  amountToDisplay,
}: WatchlistItemRowProps) => {
  const dispatch = useDispatch();
  if (amountToDisplay && index >= amountToDisplay) {
    return null;
  }

  if (!item) {
    return null;
  }

  const hasLatestQuote = 'latestQuote' in item;
  const latestQuote = hasLatestQuote ? item.latestQuote : null;

  const price =
    hasLatestQuote && item.latestQuote?.lastPrice
      ? formatNumber(item.latestQuote?.lastPrice)
      : '--';

  const marketCap =
    hasLatestQuote && item.fundamentals?.marketCap
      ? formatNumber(item.fundamentals.marketCap, '$0.0a').toUpperCase()
      : '--';

  const peRatio =
    hasLatestQuote && item.fundamentals?.peRatio
      ? item.fundamentals.peRatio
      : '--';

  // TODO: This is gross. Refactor assumptions about other component's
  // elements out.
  const handleRowClick = (
    id: string | null | undefined,
    e: React.SyntheticEvent,
  ): void => {
    const ele = e.target;
    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'EventTarget'.
    if (ele.type !== 'checkbox' && id) {
      dispatch(clickedOnSliceable(id));
    }
  };

  return (
    <Table.Row key={item.id} onClick={(e) => handleRowClick(item.id, e)}>
      <Table.Cell>
        <SliceableNameCell selectable sliceable={item} />
      </Table.Cell>
      <Table.Cell align="right">{price}</Table.Cell>
      <Table.Cell align="right">
        {isNotNil(latestQuote) ? (
          <WatchlistLatestQuoteChange latestQuote={latestQuote} />
        ) : (
          '--'
        )}
      </Table.Cell>
      <Table.Cell align="right">{marketCap}</Table.Cell>
      <Table.Cell align="right">{peRatio}</Table.Cell>
    </Table.Row>
  );
};
