import { styled, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { DashboardAnnouncements } from '~/components/Dashboard/DashboardAnnouncements';
import { useDashboardHeaderQuery } from '~/graphql/hooks';
import type { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useLayout } from '~/hooks/useLayout';

import { TopNavReferral } from '../../nav-referral/TopNavReferral';
import { StyledPageContent } from '../Navigation.styled';

import { DeveloperToolMenu } from './DeveloperToolMenu';
import { TopNavInvest } from './TopNavInvest';
import { TopNavResearch } from './TopNavResearch';
import { TopNavSettings } from './TopNavSettings';
import { TopNavSpend } from './TopNavSpend';
import { TopNavTransfers } from './TopNavTransfers';

const StyledTopNav = styled.nav`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey03}`};
  margin: 0 0;
  width: 100%;
  z-index: 100;
`;

const StyledTopBarContainer = styled(StyledPageContent)`
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
`;

const RightActions = styled(Flex)`
  & > * {
    margin-left: 8px;
  }
`;

type TopNavProps = {
  selectedNavigation: DashboardNavigation;
};

const TopNavContents = ({ selectedNavigation }: TopNavProps) => {
  switch (selectedNavigation.activeL1Nav) {
    case 'invest':
      return <TopNavInvest selectedNavigation={selectedNavigation} />;
    case 'research':
      return <TopNavResearch />;
    case 'settings':
      return <TopNavSettings selectedNavigation={selectedNavigation} />;
    case 'spend':
      return <TopNavSpend selectedNavigation={selectedNavigation} />;
    case 'transfers':
      return <TopNavTransfers selectedNavigation={selectedNavigation} />;
    default:
      return <div />;
  }
};

export const TopNav = ({ selectedNavigation }: TopNavProps) => {
  const { contentWidth, topBarHeight } = useLayout();
  const { data } = useDashboardHeaderQuery();
  const viewer = data?.viewer;

  // Doesn't surface for marketing/empty state pages
  if (selectedNavigation?.activeL3Nav?.includes('marketing')) {
    return null;
  }

  return (
    <StyledTopNav>
      <StyledTopBarContainer contentWidth={contentWidth} height={topBarHeight}>
        <TopNavContents selectedNavigation={selectedNavigation} />
        <RightActions>
          <TopNavReferral />
          <DeveloperToolMenu viewer={viewer} />
          <DashboardAnnouncements announcements={viewer?.announcements} />
        </RightActions>
      </StyledTopBarContainer>
    </StyledTopNav>
  );
};
