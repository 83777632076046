import * as React from 'react';
import {
  Controller,
  type ControllerProps,
  type FieldValues,
  type Path,
} from 'react-hook-form';

import { Dropdown, type SelectProps } from '~/toolbox/Dropdown';

type DropdownFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  SelectProps & {
    transformInput?: (v: string) => any; // display value => data value
    transformValue?: (v: TFieldValues[TName]) => any; // data value => display value
  };

const ForwardRefDropdown = React.forwardRef((props: SelectProps, ref) => (
  <Dropdown {...props} {...ref} />
));

export const ControlledDropdown = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  transformInput,
  transformValue,
  ...inputProps
}: DropdownFieldProps<TName, TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <ForwardRefDropdown
          {...field}
          {...inputProps}
          id={inputProps.id}
          value={transformValue?.(field.value) ?? field.value}
          error={fieldState.error?.message}
          onChange={(value) => {
            field.onChange(transformInput?.(value) ?? value);
          }}
        />
      )}
    />
  );
};
