import { Box, Button, Flex, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Fade, type FadeStatus } from '~/components/Animations/Fade';

import {
  ContentAndLink,
  LinkContainer,
  StyledIconButton,
  StyledLink,
  StyledToastFlex,
} from './Toast.styled';
import { type ToastProps, type ToastDurations, durations } from './Toast.types';
import { collectKindStyles } from './Toast.utils';

export const Toast = ({
  content,
  duration,
  kind,
  icon,
  onDismiss,
  ...rest
}: ToastProps & { duration?: ToastDurations }) => {
  const [status, setStatus] = React.useState<FadeStatus>('in');
  const [showToast, setShowToast] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (duration) {
      const timeoutId = window.setTimeout(() => {
        return setStatus('out');
      }, durations[duration]);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [duration]);

  const handleAnimation = () => {
    if (status === 'out') {
      if (onDismiss) {
        onDismiss();
      }
      setShowToast(!showToast);
    }
  };

  const { linkColor, backgroundColor, color, border } = collectKindStyles(kind);

  return showToast ? (
    <Fade
      width="100%"
      status={status}
      onAnimationEnd={handleAnimation}
      display="flex"
      justifyContent="center"
    >
      <StyledToastFlex
        role="status"
        data-testid="toast"
        data-kind={kind}
        color={color}
        backgroundColor={backgroundColor}
        border={border}
        {...rest}
      >
        <Flex alignItems="center" width="100%">
          <ContentAndLink>
            <Box flex="1">
              {icon && <Box marginRight={8}>{icon}</Box>}
              <Flex flexWrap="wrap">
                <PL content={content} pr={56} />
              </Flex>
            </Box>
            {'buttonProps' in rest && rest.buttonProps && (
              <Button
                {...rest.buttonProps}
                kind="link"
                fontWeight={600}
                font="PL"
                color={linkColor}
              />
            )}
            {'link' in rest && rest.link && (
              <LinkContainer color={linkColor}>
                <PL fontWeight={600}>
                  {typeof rest.link === 'object' && 'to' in rest.link ? (
                    <StyledLink color={linkColor} {...rest.link} />
                  ) : (
                    rest.link
                  )}
                </PL>
              </LinkContainer>
            )}
          </ContentAndLink>
          {!duration && (
            <StyledIconButton onClick={() => setStatus('out')}>
              <Icon name="close24" color="foregroundNeutralMain" />
            </StyledIconButton>
          )}
        </Flex>
      </StyledToastFlex>
    </Fade>
  ) : null;
};
