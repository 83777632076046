import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import type { SecurityDetail_CryptoAsset_Fragment } from '~/graphql/types';
import { formatCurrencyOrNull, formatNumber } from '~/utils';

import { SecurityDataContainer } from '../SecurityDataContainer';
import { SecurityDataRow } from '../SecurityDataRow';

export const CryptoKeyData = ({
  security,
}: {
  security: SecurityDetail_CryptoAsset_Fragment;
}) => (
  <SecurityDataContainer headingContent="Key Data" flexDirection="column">
    <Flex flexDirection="row" justifyContent="space-between">
      <Flex flexDirection="column" maxWidth={380} pr={100}>
        <SecurityDataRow
          headingContent="Current price"
          value={formatCurrencyOrNull(security.latestQuote?.lastPrice)}
        />
        {Boolean(security.marketCap) && (
          <SecurityDataRow
            headingContent="Market cap"
            value={`$${formatNumber(
              security.marketCap || 0,
              '0.0a',
            ).toUpperCase()}`}
          />
        )}
      </Flex>
      <Flex flexDirection="column" maxWidth={380}>
        {Boolean(security.volume) && (
          <SecurityDataRow
            headingContent="Average daily volume"
            value={`$${formatNumber(
              security.volume || 0,
              '0.0a',
            ).toUpperCase()}`}
          />
        )}
        {Boolean(security.circulatingSupply) && (
          <SecurityDataRow
            headingContent="Circulating supply"
            value={`${formatNumber(
              security.circulatingSupply || 0,
              '0.0a',
            ).toUpperCase()} ${security.symbol}`}
          />
        )}
        {Boolean(security.maxSupply) && (
          <SecurityDataRow
            headingContent="Maximum supply"
            value={`${formatNumber(
              security.maxSupply || 0,
              '0.0a',
            ).toUpperCase()} ${security.symbol}`}
          />
        )}
      </Flex>
    </Flex>
  </SecurityDataContainer>
);
