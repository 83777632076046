import { Input, type InputProps } from '@m1/liquid-react';
import * as React from 'react';
import {
  type ControllerProps,
  type FieldValues,
  type Path,
  useController,
} from 'react-hook-form';

type InputFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  InputProps & {
    valueIsArray?: boolean;
    valueAsNumber?: boolean;
  };

export const ControlledInput = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  defaultValue,
  error,
  helpText,
  name,
  rules,
  valueIsArray = false,
  ...forwardedInputProps
}: InputFieldProps<TName, TFieldValues>) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const {
    onChange: onChangeFormValue,
    value: formValue,
    // Make sure not to forward field.onChange() as this will cause issues
    // with input masks and inputs that have array values.
    ...forwardedFieldProps
  } = field;

  const handleOnChange = React.useCallback(
    (value: string | number | undefined) => {
      /*
       * Note -- isArray is used when the value saved in the form is a string array.
       */
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const valueArray = String(value ?? '').split(',');
      const newValue = valueIsArray
        ? // Only trim previous items, so users can type spaces (ex. full names) without being trimmed all the time. Always trim start because spaces aren't necessary at the start.
          valueArray.map((v, i) =>
            i === valueArray.length - 1 ? v.trimStart() : v.trim(),
          )
        : value;
      onChangeFormValue(newValue ?? '');
    },
    [onChangeFormValue, valueIsArray],
  );

  // Special handling for if value is an array:
  const inputValue = valueIsArray ? (formValue ?? []).join(',') : formValue;

  const errorMessage =
    typeof error === 'string' ? error : fieldState.error?.message;

  return (
    <>
      <Input
        {...forwardedInputProps}
        {...forwardedFieldProps}
        value={inputValue ?? undefined}
        error={Boolean(error) || fieldState.invalid ? errorMessage : undefined}
        helpText={helpText}
        onValueChange={(newValue) => {
          handleOnChange(newValue.expectedValue);
        }}
      />
    </>
  );
};
