import { Box, Button, Flex, HXS, PL, styled } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  emailValidator,
  phoneNumber as phoneNumberValidator,
} from '~/forms/validators';
import {
  useTrustedContactPageQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type { TrustedContactFieldsFragment } from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

type TrustedContactInput = TrustedContactFieldsFragment;

const StyledInputWrapper = styled(Box)`
  width: 100%;
  margin-right: 24px;
`;

export const TrustedContactPage = () => {
  const { data, loading } = useTrustedContactPageQuery();
  const { addToast } = useToast();
  const formMethods = useForm<TrustedContactFieldsFragment>();

  const [updateProfile, { loading: updatingProfile }] =
    useUpdateProfileMutation();

  usePortaledSpinner(updatingProfile);

  React.useEffect(() => {
    if (data?.viewer.profile?.trustedContact) {
      formMethods.reset(data?.viewer.profile?.trustedContact);
    }
  }, [data, formMethods]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const onSubmit = (trustedContact: TrustedContactInput): void => {
    updateProfile({
      variables: {
        input: {
          profile: withoutTypename({
            primary: {},
            trustedContact,
          }),
        },
      },
      onCompleted: () =>
        addToast({
          content: 'Profile updated',
          kind: 'success',
          duration: 'long',
        }),
      onError: () =>
        addToast({
          content: 'Error updated profile. Please try again later.',
          kind: 'alert',
          duration: 'long',
        }),
    });
  };

  return (
    <>
      <HXS content="Trusted Contact" pb={8} />
      <PL>
        We’ll only contact this person in certain situations.{' '}
        <Link
          to="https://help.m1.com/en/articles/9331942-what-is-a-trusted-contact"
          target="_blank"
        >
          Learn more about trusted contacts
        </Link>
      </PL>
      <Box>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Flex flexDirection="column" mt={16}>
              <StyledInputWrapper>
                <ControlledInput
                  name="firstName"
                  label="First name"
                  rules={{ required: 'Required' }}
                  control={formMethods.control}
                />
              </StyledInputWrapper>
              <Box>
                <ControlledInput
                  name="lastName"
                  label="Last name"
                  rules={{ required: 'Required' }}
                  control={formMethods.control}
                />
              </Box>
            </Flex>
            <Flex flexDirection="row">
              <StyledInputWrapper>
                <ControlledInput
                  name="email"
                  label="Email"
                  rules={{
                    required: 'Required',
                    validate: {
                      validEmail: (value) => emailValidator(value),
                    },
                  }}
                  control={formMethods.control}
                />
              </StyledInputWrapper>
              <Box minWidth={175}>
                <ControlledInput
                  label="Phone number"
                  name="phoneNumber"
                  rules={{
                    required: true,
                    validate: {
                      phoneNumber: (value) => phoneNumberValidator(value),
                    },
                  }}
                  control={formMethods.control}
                  maskType="phone"
                />
              </Box>
            </Flex>
            <Flex flexDirection="row" justifyContent="flex-end" mt={20}>
              <Button kind="primary" label="Save" type="submit" />
            </Flex>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};
