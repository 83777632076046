import { Text, type TextProps } from '@m1/liquid-react';
import React from 'react';

import type { TransferInstanceNodeFragment } from '~/graphql/types';
import { formatNumber } from '~/utils';

type TransferInstanceAmountTextProps = Partial<TextProps> & {
  emphasizeFailureState: boolean;
  transferInstance: TransferInstanceNodeFragment;
};

export const TransferInstanceAmountText = (
  props: TransferInstanceAmountTextProps,
) => {
  const { transferInstance, emphasizeFailureState, ...textElementProps } =
    props;

  const getTransferInstanceContent = (
    transfer: TransferInstanceNodeFragment,
  ) =>
    typeof transfer.amount !== 'number'
      ? '$--'
      : formatNumber(transfer.amount, '$0,0[.]00');

  const getTransferInstanceLineDecoration = (
    transfer: TransferInstanceNodeFragment,
  ) =>
    transfer.status.isFailed || transfer.status.isCanceled
      ? 'line-through'
      : 'none';

  return (
    <Text
      content={getTransferInstanceContent(transferInstance)}
      textDecorationLine={
        emphasizeFailureState
          ? getTransferInstanceLineDecoration(transferInstance)
          : undefined
      }
      {...textElementProps}
    />
  );
};
