import { Button, spacingUnits, styled, Card } from '@m1/liquid-react';
import React from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';

import { TransferDetailsMessageCard } from '~/components/TransferDetailsMessageCard';
import { useWizardContext } from '~/flows/wizard';
import type { Account } from '~/graphql/types';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { TransferScheduleOptions } from '../components/RecurringTransferOptions/TransferScheduleOptions';
import { TransferAmountInputField } from '../components/TransferAmountInputFields';
import { TransferCryptoDisclosure } from '../components/TransferCryptoDisclosure';
import { TransferIRAFields } from '../components/TransferIRAFields';
import { TransferParticipantFields } from '../components/TransferParticipantFields/TransferParticipantFields';
import { TransferTimingMessage } from '../components/TransferTimingMessage';
import { CreateTransferContext } from '../CreateTransferContext';
import { useParticipantSummary } from '../utils/useParticipantSummary';
import { useTransferRequirements } from '../utils/useTransferRequirements';

import {
  type TransferDetailsFormValues,
  TransferFrequencyEnum,
} from './TransferDetails.types';

export const StyledTransfersCard = styled(Card)`
  margin: auto;
  margin-top: 144px;
  margin-bottom: 64px;
  max-width: 500px;
  width: 100%;
  padding: ${spacingUnits.l}px;
`;

export const TransferDetailsStep = () => {
  const { goTo } = useWizardContext();
  const dispatch = useDispatch();
  const transferContext = React.useContext(CreateTransferContext);
  const mode = transferContext?.mode;

  const persistedFormValues = useSelector((state) => {
    return state.reactHookForm['createTransferDetails'];
  });

  const formMethods = useForm<TransferDetailsFormValues>({
    defaultValues: {
      schedule: undefined,
      sourceId: transferContext?.fromParticipantIdQueryParam,
      destinationId: transferContext?.toParticipantIdQueryParam,
      amount: 0,
      ...persistedFormValues,
    },
    mode: 'all',
  });

  const { formState, handleSubmit, setValue, clearErrors } = formMethods;

  const [isRollOver, contributionYear, sourceId, destinationId, frequency] =
    formMethods.watch([
      'isRollOver',
      'contributionYear',
      'sourceId',
      'destinationId',
      'frequency',
    ]);

  const isPriorYear = contributionYear === 'PRIOR_YEAR';

  // Set form values if they're passed in via query param
  React.useEffect(() => {
    if (mode && Boolean(persistedFormValues) === false) {
      const newMode =
        mode === 'SCHEDULE'
          ? TransferFrequencyEnum.Weekly
          : TransferFrequencyEnum.OneTime;

      setValue('frequency', newMode, {
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [mode, persistedFormValues, setValue]);

  const onSubmit: SubmitHandler<TransferDetailsFormValues> = (formValues) => {
    /*
     * Below we are employing the ReactHookFormReducer pattern.
     * This pattern is documented in detail here:
     * https://m1finance.atlassian.net/wiki/x/xQCs4Q
     */
    dispatch(
      submitReactFormData({
        createTransferDetails: formValues,
      }),
    );

    goTo('TRANSFER_VALIDATION');
  };

  const requirements = useTransferRequirements({
    form: formMethods,
    scenario: transferContext?.scenarioQueryParam,
  });

  /*
   * Several of the sub-components below require the selected
   * source and destination details.
   * We determine those values in this parent component and pass
   * them down as props to prevent redundant lookups in the sub-components.
   */
  const participantSummary = useParticipantSummary({
    sourceId,
    destinationId,
    frequency,
    pivot: transferContext?.pivot,
  });

  const { sourceDetails, destinationDetails } = participantSummary;

  const sourceParticipantIsBorrowAccount =
    sourceDetails?.account?.transferParticipantType === 'BORROW';

  const sourceParticipantIsRetirementAccount =
    (sourceDetails?.account as Account)?.isRetirement ?? false;

  /*
   * We disable the frequency options if:
   * - The source participant is not selected
   * - The source participant is a Borrow account
   * - The source participant is a Retirement account
   * - The transfer is a IRA contribution rollover
   * - The transfer is a prior year IRA contribution
   */
  const scheduleIsDisabled = Boolean(
    (sourceId && !sourceDetails) ||
      sourceParticipantIsBorrowAccount ||
      sourceParticipantIsRetirementAccount ||
      isRollOver === true ||
      isPriorYear,
  );

  // If the schedule is disabled, we reset the frequency to "One-Time"
  React.useEffect(() => {
    if (scheduleIsDisabled) {
      setValue('frequency', TransferFrequencyEnum.OneTime, {
        shouldTouch: true,
        shouldValidate: true,
      });
      setValue('schedule', undefined);
      clearErrors('schedule');
    }
  }, [scheduleIsDisabled, setValue, clearErrors]);

  return (
    <FormProvider {...formMethods}>
      <form name="create-transfer-details" onSubmit={handleSubmit(onSubmit)}>
        <StyledTransfersCard data-testid="card">
          <TransferAmountInputField sourceDetails={sourceDetails} />
          <TransferParticipantFields participantSummary={participantSummary} />
          {!transferContext?.scenarioQueryParam && (
            <TransferScheduleOptions isDisabled={scheduleIsDisabled} />
          )}
          <TransferIRAFields
            sourceDetails={sourceDetails}
            destinationDetails={destinationDetails}
            disableContributionYear={
              requirements?.isIraContributionYearRequired === false
            }
          />
          {requirements?.detailsMessage && (
            <TransferDetailsMessageCard
              detailsMessage={requirements.detailsMessage}
              onTransferSuggestionLinkClick={(event, transferSuggestion) => {
                if (transferSuggestion) {
                  setValue('amount', transferSuggestion.amount?.toString());
                  setValue('sourceId', transferSuggestion.fromParticipant?.id);
                  setValue(
                    'destinationId',
                    transferSuggestion.toParticipant?.id,
                  );
                }
              }}
              mb={32}
            />
          )}
          <TransferCryptoDisclosure
            sourceDetails={sourceDetails}
            destinationDetails={destinationDetails}
          />
          <TransferTimingMessage transferRequirements={requirements ?? null} />
          <Button
            type="submit"
            label="Review"
            fullWidth
            disabled={!formState.isValid}
          />
        </StyledTransfersCard>
      </form>
    </FormProvider>
  );
};
