import { Button, Flex, HXS, PL, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useMarginCallInformationOptionsStepQuery } from '~/graphql/hooks';
import { selectedCreateTransferResolutionOption } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { MARGIN_CALL_INFORMATION_STEPS as STEPS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';
import { formatNumber } from '~/utils';

type MarginCallInformationOptionsProps = {
  onFinishStep: (step?: ValueOf<typeof STEPS>) => void;
};

// Styled Components
const StyledContainer = styled(Flex)`
  margin: auto;
  width: 797px;
`;

const StyledCard = styled(Card)<{
  mr?: number;
}>`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.mr || 0}px;
  padding: 32px 48px;
  width: 378px;
`;

export const MarginCallInformationOptionsStep = ({
  onFinishStep,
}: MarginCallInformationOptionsProps) => {
  const dispatch = useDispatch();
  const borrowAccountId = useSelector(
    (state) => state.newFlows.MARGIN_CALL_INFORMATION.borrowAccountId,
  );

  const { data, loading } = useMarginCallInformationOptionsStepQuery({
    variables: {
      borrowAccountId: borrowAccountId as string,
    },
    skip: !borrowAccountId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spinner />;
  }

  if (data?.node?.__typename !== 'BorrowAccount') {
    return <GenericSystemError />;
  }

  const marginCallMarginEquityDeficit =
    data.node.status?.maintenanceCallMarginEquityDeficit;

  const handleViewHoldingsClick = (): void => {
    onFinishStep();
  };

  const formattedMarginCallMarginEquityDeficit = marginCallMarginEquityDeficit
    ? formatNumber(marginCallMarginEquityDeficit)
    : '$--';

  return (
    <StyledContainer flexDirection="column">
      <BackArrow
        content="Margin Call Info"
        mb={24}
        mt={148}
        // @ts-ignore dispatch needs to be updated
        onClick={() => dispatch(onFinishStep(STEPS.INFO_SCREEN))}
      />
      <Flex flexGrow="1" justifyContent="center">
        <StyledCard mr={32}>
          <HXS content="Deposit additional funds" mb={16} />
          <PL flex={1}>
            The easiest way to resolve the call is to pay back the amount of{' '}
            <b>{formattedMarginCallMarginEquityDeficit}</b> by transferring
            directly from one of your accounts to your Borrow account.
          </PL>
          <Flex justifyContent="center">
            <Button
              kind="link"
              label="Deposit Funds"
              mt={32}
              // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
              onClick={() => dispatch(selectedCreateTransferResolutionOption())}
            />
          </Flex>
        </StyledCard>
        <StyledCard>
          <HXS content="Sell securities" mb={16} />
          <PL>
            If you do not have the funds to pay back the amount of{' '}
            <b>{formattedMarginCallMarginEquityDeficit}</b> directly, you can
            sell a portion of your portfolio. After selling, you will need to
            transfer those funds to your Borrow account to pay back the balance.
          </PL>
          <Flex justifyContent="center">
            <Button
              kind="link"
              label="View Holdings"
              mt={32}
              onClick={handleViewHoldingsClick}
            />
          </Flex>
        </StyledCard>
      </Flex>
    </StyledContainer>
  );
};
