import { Box } from '@m1/liquid-react';
import moment from 'moment';
import React from 'react';

import type { BorrowBillNodeFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { Pill } from '~/toolbox/Pill';
import { formatNumber } from '~/utils';

import { BillStatementDetailsModal } from './BillStatementDetailsModal';

type BorrowBillRowProps = {
  billNode: BorrowBillNodeFragment;
};
export const BorrowBillRow = ({ billNode }: BorrowBillRowProps) => {
  const [isBillingDetailsModalOpen, setIsBillingDetailsModalOpen] =
    React.useState(false);
  return (
    <GridTable.NavigableRow
      onClick={() => {
        if (!isBillingDetailsModalOpen) {
          setIsBillingDetailsModalOpen(true);
        }
      }}
    >
      <GridTable.Cell>
        <Box as="span" pr={8}>
          {moment(billNode.endDate).format('ll')}
        </Box>
      </GridTable.Cell>
      <GridTable.Cell justifySelf="end">
        <>
          {billNode.isDue && <Pill kind="caution" label="Pending" mr={8} />}
          {billNode.amount && (
            <Box as="span">{formatNumber(billNode.amount)}</Box>
          )}
        </>
      </GridTable.Cell>
      <BillStatementDetailsModal
        borrowBill={billNode}
        open={isBillingDetailsModalOpen}
        onClose={() => {
          setIsBillingDetailsModalOpen(false);
        }}
      />
    </GridTable.NavigableRow>
  );
};
