import * as React from 'react';

import type { GetTransferWizardPreloadQueryResult } from '~/graphql/hooks';
import type {
  TransferParticipantSideEnum,
  TransferScenarioTypeEnum,
} from '~/graphql/types';
import { CREATE_TRANSFER_FLOW_MODES } from '~/static-constants';

export type TransferMode = keyof typeof CREATE_TRANSFER_FLOW_MODES;

export type CreateTransferContextType = {
  mode: TransferMode;
  pivot: TransferParticipantSideEnum;
  scenarioQueryParam: TransferScenarioTypeEnum | null;
  fromParticipantIdQueryParam: string | null;
  toParticipantIdQueryParam: string | null;
  idempotencyKey: string | null | undefined;
} & GetTransferWizardPreloadQueryResult;

export const CreateTransferContext =
  React.createContext<CreateTransferContextType | null>(null);
