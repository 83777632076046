import { PL, type Theme } from '@m1/liquid-react';
import * as d3 from 'd3';
import ceil from 'lodash-es/ceil';
import uniqueId from 'lodash-es/uniqueId';
import * as React from 'react';

import type { InvestFundingFragment } from '~/graphql/types';
import { withTheme } from '~/hocs';
import { formatNumber } from '~/utils';

import style from './brokerage-style.module.scss';

export type BrokerageTotalsProps = {
  account: InvestFundingFragment;
};

type ProvidedProps = {
  theme: Theme;
};

type ComponentProps = BrokerageTotalsProps & ProvidedProps;

class BrokerageTotalsComponent extends React.Component<ComponentProps> {
  id: string = uniqueId('brokeragetotal'); // eslint-disable-line

  ref: Element | null | undefined;

  // @ts-expect-error - TS2564 - Property 'width' has no initializer and is not definitely assigned in the constructor.
  width: number;
  // @ts-expect-error - TS2564 - Property 'height' has no initializer and is not definitely assigned in the constructor.
  height: number;

  // @ts-expect-error - TS2564 - Property 'svg' has no initializer and is not definitely assigned in the constructor.
  svg: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'g' has no initializer and is not definitely assigned in the constructor.
  g: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'x0' has no initializer and is not definitely assigned in the constructor.
  x0: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'x1Both' has no initializer and is not definitely assigned in the constructor.
  x1Both: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'x1One' has no initializer and is not definitely assigned in the constructor.
  x1One: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'y' has no initializer and is not definitely assigned in the constructor.
  y: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'z' has no initializer and is not definitely assigned in the constructor.
  z: (...args: Array<any>) => any;

  // @ts-expect-error - TS2564 - Property 'xAxis' has no initializer and is not definitely assigned in the constructor.
  xAxis: (...args: Array<any>) => any;
  // @ts-expect-error - TS2564 - Property 'yAxis' has no initializer and is not definitely assigned in the constructor.
  yAxis: (...args: Array<any>) => any;

  componentDidMount() {
    if (!this.ref) {
      return;
    }
    const clientRect = this.ref.getBoundingClientRect();
    const margin = {
      top: 0,
      right: 0,
      bottom: 30,
      left: 60,
    };
    const { primary, foregroundNeutralTertiary } = this.props.theme.colors;
    this.width = clientRect.width - margin.left - margin.right;
    this.height = clientRect.height - margin.top - margin.bottom;

    // Setup root elements
    // @ts-expect-error - TS2322 - Type 'Selection<BaseType, unknown, HTMLElement, any>' is not assignable to type '(...args: any[]) => any'.
    this.svg = d3.select(`#${this.id}`);
    this.g = this.svg
      // @ts-expect-error - TS2339 - Property 'append' does not exist on type '(...args: any[]) => any'.
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Setup scales
    this.x0 = d3.scaleBand().rangeRound([0, this.width]).paddingInner(0.1);

    this.x1Both = d3.scaleBand();
    this.x1One = d3.scaleBand();
    this.y = d3.scaleLinear().rangeRound([this.height, 5]);
    this.z = d3.scaleOrdinal().range([primary, foregroundNeutralTertiary]);

    // Setup axises
    // @ts-expect-error - TS2345 - Argument of type '(...args: any[]) => any' is not assignable to parameter of type 'AxisScale<any>'.
    this.xAxis = d3.axisBottom(this.x0);
    this.yAxis = d3
      // @ts-expect-error - TS2345 - Argument of type '(...args: any[]) => any' is not assignable to parameter of type 'AxisScale<any>'.
      .axisLeft(this.y)
      .tickFormat(d3.format('$,.0f'))
      .tickSizeInner(-this.width)
      .tickSizeOuter(0)
      .tickPadding(7);

    // Render X-axis
    this.g
      // @ts-expect-error - TS2339 - Property 'append' does not exist on type '(...args: any[]) => any'.
      .append('g')
      .attr('class', style.xAxis)
      .attr('transform', `translate(0, ${this.height})`)
      .call(this.xAxis);

    // Render Y-axis
    // @ts-expect-error - TS2339 - Property 'append' does not exist on type '(...args: any[]) => any'.
    this.g.append('g').attr('class', style.yAxis).call(this.yAxis);

    this.update(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
    if (this.props.account.id !== nextProps.account.id) {
      this.update(nextProps);
    }
  }

  render() {
    return (
      <svg
        className={style.root}
        id={this.id}
        width="100%"
        height="250"
        ref={(c) => {
          this.ref = c;
        }}
      />
    );
  }

  update(props: ComponentProps) {
    const keys = ['totalDeposits', 'totalWithdrawals'];
    const totalsByYear = readFundingTotals(props);
    if (!totalsByYear) {
      return <PL content="Temporarily unable to retrieve funding history." />;
    }

    // Tweaks data structure to make iteration easier in d3 selections.
    // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
    const dataMapper = (d) => {
      return keys
        .filter((k) => d[k])
        .map((key) => {
          const inverse =
            key === 'totalDeposits'
              ? d['totalWithdrawals']
              : d['totalDeposits'];
          return {
            key,
            value: d[key],
            inverse,
          };
        });
    };

    // Update X scale domains
    // @ts-expect-error - TS2339 - Property 'domain' does not exist on type '(...args: any[]) => any'.
    this.x0.domain(totalsByYear.map((d) => d.year));
    // @ts-expect-error - TS2339 - Property 'domain' does not exist on type '(...args: any[]) => any'. | TS2339 - Property 'bandwidth' does not exist on type '(...args: any[]) => any'.
    this.x1Both.domain(keys).rangeRound([0, this.x0.bandwidth()]);
    // @ts-expect-error - TS2339 - Property 'domain' does not exist on type '(...args: any[]) => any'. | TS2339 - Property 'bandwidth' does not exist on type '(...args: any[]) => any'.
    this.x1One.domain(['value']).rangeRound([0, this.x0.bandwidth()]);

    // Update Y-scale domain. Add bit of padding between top of graph and top of data.
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly year: number; readonly totalDeposits: number; readonly totalWithdrawals: number; }'.
    const max = d3.max(totalsByYear, (d) => d3.max(keys, (k) => d[k]));
    const adjustedMax = ceil(Math.max(max * 1.07, 1000), -3);
    // @ts-expect-error - TS2339 - Property 'domain' does not exist on type '(...args: any[]) => any'.
    this.y.domain([0, adjustedMax]);

    // @ts-expect-error - TS2339 - Property 'domain' does not exist on type '(...args: any[]) => any'.
    this.z.domain(keys);

    // Update X-axis
    // @ts-expect-error - TS2339 - Property 'select' does not exist on type '(...args: any[]) => any'.
    this.g.select(`.${style.xAxis}`).call(this.xAxis);

    // Update Y-axis ticks
    // @ts-expect-error - TS2339 - Property 'domain' does not exist on type '(...args: any[]) => any'.
    const [, yMax] = this.y.domain();
    const yTickValues = [0, yMax * 0.25, yMax * 0.5, yMax * 0.75, yMax];
    // @ts-expect-error - TS2339 - Property 'select' does not exist on type '(...args: any[]) => any'. | TS2339 - Property 'tickValues' does not exist on type '(...args: any[]) => any'.
    this.g.select(`.${style.yAxis}`).call(this.yAxis.tickValues(yTickValues));

    // Poor-man's reset
    // @ts-expect-error - TS2339 - Property 'selectAll' does not exist on type '(...args: any[]) => any'.
    this.g.selectAll(`.${style.group}`).remove();

    // Render data
    const intG = this.g
      // @ts-expect-error - TS2339 - Property 'append' does not exist on type '(...args: any[]) => any'.
      .append('g')
      .attr('class', style.group)
      .selectAll('g')
      .data(totalsByYear)
      .enter()
      .append('g')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('transform', (d) => `translate(${this.x0(d.year)}, 0)`);

    intG
      .selectAll('rect')
      .data(dataMapper)
      .enter()
      .append('rect')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('x', (d) => {
        return d.inverse
          ? this.x1Both(d.key)
          : // @ts-expect-error - TS2339 - Property 'bandwidth' does not exist on type '(...args: any[]) => any'.
            this.x1One('value') + this.x1One.bandwidth() / 4;
      })
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('y', (d) => this.y(d.value))
      // @ts-expect-error - TS2339 - Property 'bandwidth' does not exist on type '(...args: any[]) => any'.
      .attr('width', this.x1Both.bandwidth())
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('height', (d) => this.height - this.y(d.value))
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('fill', (d) => this.z(d.key));

    intG
      .selectAll('text')
      .data(dataMapper)
      .enter()
      .append('text')
      .attr('class', style.labelText)
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('x', (d) => {
        const x = d.inverse
          ? // @ts-expect-error - TS2339 - Property 'bandwidth' does not exist on type '(...args: any[]) => any'.
            this.x1Both(d.key) + this.x1Both.bandwidth() / 2
          : // @ts-expect-error - TS2339 - Property 'bandwidth' does not exist on type '(...args: any[]) => any'.
            this.x1One('value') + this.x1One.bandwidth() / 2;
        return ceil(x);
      })
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('y', (d) => this.y(d.value) - 3)
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .text((d) => formatNumber(d.value));
  }
}

function readFundingTotals(props: ComponentProps) {
  const { account } = props;
  return account.fundingTotals ? account.fundingTotals.totalsByYear : null;
}

export const BrokerageTotals = withTheme(BrokerageTotalsComponent) as any;
