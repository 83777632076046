import {
  Box,
  Button,
  Flex,
  HM,
  HXXS,
  Modal,
  ModalContent,
  PL,
  PS,
} from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import {
  useUpdateEmailMutation,
  useInitializeTwoFactorVerificationMutation,
} from '~/graphql/hooks';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';
import { formatPhoneNumber } from '~/utils';

type UpdateEmailProps = {
  email: string;
  phoneNumber: string;
};

export const UpdateEmail = ({ email, phoneNumber }: UpdateEmailProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { addToast } = useToast();
  const formMethods = useForm({
    defaultValues: { newEmail: email, confirmationCode: '' },
  });

  const newEmail = formMethods.watch('newEmail');
  const confirmationCode = formMethods.watch('confirmationCode');

  const [initializeTwoFactorVerification] =
    useInitializeTwoFactorVerificationMutation({
      variables: {
        input: {
          phoneNumber: phoneNumber,
          channel: 'SMS',
        },
      },
    });

  const [updateEmail, { loading }] = useUpdateEmailMutation({
    variables: {
      input: {
        newEmail: newEmail || email,
        oldEmail: email,
        phoneNumber: phoneNumber,
        verificationCode: confirmationCode,
      },
    },
  });

  const handleInitializeEmailUpdate = () => {
    initializeTwoFactorVerification({
      onCompleted: () => {
        setModalOpen(true);
      },
      onError: () => {
        addToast({
          content:
            'Unable to update your email name at this time. Please try again later or contact support.',
          kind: 'alert',
          duration: 'long',
        });
      },
    });
  };

  const handleConfirmationCode = () => {
    updateEmail({
      onCompleted: (d) => {
        addToast({
          content:
            d?.updateEmail.successMessage ||
            'Your email name has been updated.',
          kind: 'success',
          duration: 'short',
        });
      },
      onError: (error) => {
        addToast({
          content:
            error.message ||
            'Unable to update your email name at this time. Please try again later or contact support.',
          kind: 'alert',
          duration: 'long',
        });
      },
    });

    setModalOpen(false);
  };

  return (
    <form onSubmit={formMethods.handleSubmit(handleConfirmationCode)}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContent width="wide">
          <Box>
            <HM my={32} content="Let’s make sure it’s you." />
            <PL
              mb={48}
              content={`We sent a confirmation code to ${formatPhoneNumber(
                phoneNumber,
              )}. Please enter it below.`}
            />
            <ControlledInput
              control={formMethods.control}
              type="email"
              name="confirmationCode"
              label="Confirmation code"
            />
            <Button
              mt={144}
              label="Continue"
              onClick={handleConfirmationCode}
            />
          </Box>
        </ModalContent>
      </Modal>
      <Flex flexDirection="column" pb={16}>
        <HXXS content="Email" />
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Box width="75%">
            <ControlledInput
              control={formMethods.control}
              name="newEmail"
              label="Email"
            />
            <PS
              my={8}
              color="foregroundNeutralSecondary"
              content="Your email is also your username"
            />
          </Box>
          {loading && <Spinner radius={22} thickness={3} />}
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleInitializeEmailUpdate();
            }}
            label="Confirm"
            disabled={loading || email === newEmail}
            marginTop={21}
            mx={16}
            size="medium"
          />
        </Flex>
      </Flex>
    </form>
  );
};
