import { Banner, Box, Button, HXXS, PM, styled, Card } from '@m1/liquid-react';
import * as React from 'react';
import { change, SubmissionError } from 'redux-form';

import { PaymentDetailsMessageCard } from '~/components/TransferDetailsMessageCard/PaymentDetailsMessageCard';
import type {
  Borrow,
  ContraTransferParticipantInput,
  CreateTransferInstanceInput,
  Credit,
  ScheduledTransferIndicatorEnum,
  ScheduledTransferRule,
  TransferParticipantList,
  TransferRequirements,
} from '~/graphql/types';
import { compose, connectForm, UNSAFE_connectRedux } from '~/hocs';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import type { AppState } from '~/redux';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';
import type { DropdownGroup } from '~/toolbox/Dropdown';
import { Pill, PillGroup } from '~/toolbox/Pill';
import { isNil, isNotNil } from '~/utils';
import { parseCurrency } from '~/utils/parseCurrency';

import { PaymentPresets } from '../components/PaymentPresets';

import { TransferParticipantDropdownField } from './fields';
import { TextField, type TextFieldProps } from './fields/text-field';

type CreateOneTimeOrScheduledPaymentProps = {
  sourceParticipants: DropdownGroup[];
  destinationParticipants: DropdownGroup[];
  otherAmount: number | null | undefined;
  selectedPreset: string | null | undefined;
  dispatch: (...args: Array<any>) => any;
  navigate: (...args: Array<any>) => any;
  fromParticipantId: string | null | undefined;
  handleSubmit: (...args: Array<any>) => any;
  hasAnyTransferInput: boolean;
  hasValidTransferInput: boolean;
  initialValues: Partial<CreateTransferInstanceInput>;
  isCreditAutoPayEnabled: boolean;
  isCreditCardPayment: boolean;
  isPersonalLoanPayment: boolean;
  isPersonalLoansAutoPayEnabled: boolean;
  isSchedule: boolean;
  onSubmit: (...args: Array<any>) => any;
  onSwitchModes: (mode: ValueOf<typeof MODES>) => void;
  showTransactionError: boolean;
  toParticipant: ContraTransferParticipantInput | null | undefined;
  toParticipantId: string | null | undefined;
  transferAmountFromStore: number;
  viewer:
    | {
        borrow: Borrow | null | undefined;
        credit: Credit | null | undefined;
        transfers: TransfersAlias | null | undefined;
      }
    | null
    | undefined;
};

type CreateOneTimeOrScheduleTransferState = {
  isInputAmountInteractedWith: boolean;
  sentTransferRequest: boolean;
  pillSelected: boolean;
};

export type TransfersAlias = {
  destinationParticipants: TransferParticipantList | null | undefined;
  id: string;
  isEvenWeek: boolean | null | undefined;
  requirements: TransferRequirements | null | undefined;
  sourceParticipants: TransferParticipantList | null | undefined;
  defaultAmountPills: Array<{ label: string; kind: string }>;
};

const PRESET_OTHER = {
  value: 'OTHER_AMOUNT',
  amountFieldName: 'otherAmount',
};

const AutoPayBannerButton = styled(Button)`
  white-space: nowrap;
  align-self: end;
`;

type CreateOneTimeOrScheduledPaymentFormProps = {
  fromParticipantId: string | null | undefined;
  toParticipantId: string | null | undefined;
  otherAmount: string | null | undefined;
  selectedPreset: string;
  sourceParticipants: DropdownGroup[];
  destinationParticipants: DropdownGroup[];
};

class CreateOneTimeOrScheduledPayment extends React.Component<
  CreateOneTimeOrScheduledPaymentProps,
  CreateOneTimeOrScheduleTransferState
> {
  amountInputId: string = 'amount-input-id';

  state = {
    isInputAmountInteractedWith: false,
    sentTransferRequest: false,
    pillSelected: false,
  };

  render() {
    const { sourceParticipants, destinationParticipants } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <HXXS color="foregroundNeutralMain" content="Accounts" />
        <TransferParticipantDropdownField
          label="From"
          name="fromParticipantId"
          options={sourceParticipants}
        />
        <TransferParticipantDropdownField
          label="To"
          name="toParticipantId"
          options={destinationParticipants}
          style={{
            marginTop: 16,
          }}
          disabled
        />
        <HXXS color="foregroundNeutralMain" content="Amount" mt={24} />
        {this.renderPaymentAmounts()}
        {this.renderErrorLink()}
        {this.renderRequirementsMessage()}
        {this.renderAutoPaymentDate()}
        {this.renderCreditCardAutoPaymentTermsLink()}
        {this.renderAutoPayLink()}
        <div
          style={{
            paddingTop: 64,
            textAlign: 'center',
          }}
        >
          <Button
            disabled={this.isPaymentButtonDisabled()}
            kind="primary"
            size="large"
            type="submit"
          >
            {!this.props.isSchedule ? 'Make a payment' : 'Continue'}
          </Button>
        </div>
      </form>
    );
  }

  renderAutoPaymentDate() {
    const reqs = this.readTransfers()?.requirements;
    if (!reqs) {
      return null;
    }
    return (
      this.props.isSchedule && (
        <>
          {reqs.autoPayInformationContent && (
            <>
              <HXXS content="Payment Date" mt={24} mb={16} />
              <Card label="AutoPay">
                <PM color="foregroundNeutralMain">
                  {reqs.autoPayInformationContent}
                </PM>
              </Card>
            </>
          )}
          {reqs.autoPayDueDateMessage && (
            <Banner
              mt={16}
              kind="alert"
              content={reqs.autoPayDueDateMessage}
              size="inline"
              action={
                <AutoPayBannerButton
                  kind="link"
                  style={{ fontSize: '16px' }}
                  onClick={() => this.props.onSwitchModes(MODES.ONE_TIME)}
                >
                  One-time payment
                </AutoPayBannerButton>
              }
            />
          )}
        </>
      )
    );
  }

  renderCreditCardAutoPaymentTermsLink() {
    const reqs = this.readTransfers()?.requirements;
    if (!reqs || !reqs.transferOverviewLink || !this.props.isSchedule) {
      return null;
    }
    return <LinkableLink linkable={reqs.transferOverviewLink} mt={32} />;
  }

  readPersonalLoanAutoPayInstance(): ScheduledTransferRule | null | undefined {
    const loans = this.props.viewer?.borrow?.personalLoans?.loans?.edges;

    const selectedLoan = loans?.find(
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      (loan) => loan.node?.id === this.props.toParticipantId,
    );

    return selectedLoan?.node?.autoPayInstance;
  }

  renderAutoPayLink() {
    const {
      isCreditCardPayment,
      isPersonalLoanPayment,
      isPersonalLoansAutoPayEnabled,
      viewer,
      navigate,
    } = this.props;

    if (this.props.isSchedule) {
      return null;
    }

    if (isPersonalLoanPayment && !isPersonalLoansAutoPayEnabled) {
      return null;
    }

    const personalLoanAutoPayInstance = isPersonalLoanPayment
      ? this.readPersonalLoanAutoPayInstance()
      : null;

    let content = '';
    let onClick;

    if (isCreditCardPayment && viewer?.credit?.autoPayInstance) {
      content = 'View AutoPay Details';
      const transferRuleId = viewer.credit.autoPayInstance.id;
      onClick = () =>
        navigate({
          to: '/d/rule-details/:transferRuleId',
          params: { transferRuleId },
        });
    } else if (isPersonalLoanPayment && isNotNil(personalLoanAutoPayInstance)) {
      const transferRuleId = personalLoanAutoPayInstance?.id;
      content = 'View AutoPay Details';
      onClick = () =>
        navigate({
          to: '/d/rule-details/:transferRuleId',
          params: { transferRuleId },
        });
    }
    // User does not have AutoPay enabled:
    else {
      content = 'Enable AutoPay';
      onClick = () => this.props.onSwitchModes(MODES.SCHEDULE);
    }
    return (
      <Button kind="text" onClick={onClick} mt={24}>
        {content}
      </Button>
    );
  }

  renderOtherAmountField({
    ...props
  }: Partial<TextFieldProps & { isRequired: boolean }>) {
    const { showTransactionError } = this.props;
    const validations = [this.limitsValidation];
    const amountPills = this.props.viewer?.transfers?.defaultAmountPills;

    return (
      <Box>
        <Box>
          <TextField
            {...props}
            name={PRESET_OTHER.amountFieldName}
            maskType="money"
            mask={{
              allowNegative: false,
            }}
            maxLength={12}
            autoComplete="off"
            autoFocus={false}
            id={this.amountInputId}
            nonSubmissionError={showTransactionError}
            validate={validations}
            onChange={() => {
              this.setState({
                isInputAmountInteractedWith:
                  !this.state.isInputAmountInteractedWith || true,
                pillSelected: false,
              });
            }}
          />
        </Box>
        <Box justifyContent="center">
          <PillGroup justifyContent="center">
            {amountPills?.map(({ label }) => (
              <Pill
                key={`pill-${label}`}
                label={label}
                size="large"
                kind="interactive"
                onClick={() => {
                  this.setState({
                    pillSelected: true,
                  });
                  this.props.dispatch(
                    change(
                      'transfer-instance',
                      PRESET_OTHER.amountFieldName,
                      label.substring(1).replace(',', ''),
                    ),
                  );
                }}
              />
            ))}
          </PillGroup>
        </Box>
      </Box>
    );
  }

  renderPaymentAmounts() {
    const { dispatch } = this.props;
    const reqs = this.readTransfers()?.requirements;
    const presets = this.props.isSchedule
      ? reqs?.scheduledTransferAmountPresets
      : reqs?.transferAmountPresets;

    if (!presets) {
      // If no presets are available, we assume a one-time
      // PL payment, and render the amount field.
      return this.renderOtherAmountField({
        disabled: false,
        onFocus: () => {
          dispatch(
            change('transfer-instance', 'selectedPreset', PRESET_OTHER.value),
          );
        },
      });
    }

    const isOtherDisabled = presets.every((preset) => preset?.value === 0);

    return (
      <Card label="Select amount" mt={16}>
        <PaymentPresets
          otherAmountField={this.renderOtherAmountField({
            disabled: isOtherDisabled,
            onFocus: () => {
              dispatch(
                change(
                  'transfer-instance',
                  'selectedPreset',
                  PRESET_OTHER.value,
                ),
              );
            },
          })}
          selectedPreset={this.props.selectedPreset}
          presets={presets}
          presetOther={PRESET_OTHER.value}
          isOtherDisabled={isOtherDisabled}
          isSchedule={this.props.isSchedule}
          onSelect={(value) => {
            // clear the "other amount" field when a different preset is selected
            if (value !== PRESET_OTHER.value) {
              dispatch(
                change('transfer-instance', PRESET_OTHER.amountFieldName, ''),
              );
            }
          }}
        />
      </Card>
    );
  }

  renderErrorLink() {
    const transfers = this.readTransfers();

    if (
      !transfers?.requirements?.maxTransferAmountErrorLink ||
      !this.props.transferAmountFromStore ||
      isNil(transfers.requirements.maxTransferAmount)
    ) {
      return null;
    }

    return Number(this.props.transferAmountFromStore) >
      transfers.requirements.maxTransferAmount ? (
      <LinkableLink
        mt={16}
        linkable={transfers.requirements.maxTransferAmountErrorLink}
      />
    ) : null;
  }

  isPaymentButtonDisabled(): boolean {
    const { isCreditCardPayment, hasValidTransferInput } = this.props;
    return isCreditCardPayment && !hasValidTransferInput;
  }

  handleSubmit = ({
    otherAmount,
    selectedPreset,
    fromParticipantId,
    toParticipantId,
  }: CreateOneTimeOrScheduledPaymentFormProps): void => {
    const amount =
      selectedPreset === PRESET_OTHER.value
        ? parseCurrency(otherAmount)
        : parseFloat(selectedPreset);

    if (!this.state.sentTransferRequest) {
      /**
       * Do a final check for validation on submit.
       * Throw a SubmissionError (redux-form) to surface
       * the error to the user.
       */
      const error = this.limitsValidation(amount ?? 0);
      if (error) {
        throw new SubmissionError({
          otherAmount: error,
        });
      }

      const scheduleAndLabel = this.getScheduleAndLabel(selectedPreset);

      this.props.onSubmit({
        ...scheduleAndLabel,
        selectedPreset,
        fromParticipantId,
        toParticipantId,
        otherAmount,
        amount,
        isCreditCardPayment: this.props.isCreditCardPayment,
        isPersonalLoanPayment: this.props.isPersonalLoanPayment,
      });
      this.setState({
        sentTransferRequest: true,
      });
    }
  };

  readTransfers(): TransfersAlias | null | undefined {
    return this.props.viewer && this.props.viewer.transfers;
  }

  getScheduleAndLabel = (
    indicator:
      | ValueOf<ScheduledTransferIndicatorEnum>
      | typeof PRESET_OTHER.value,
  ) => {
    let label;
    const autoPayPaymentValue =
      indicator === PRESET_OTHER.value ? 'FIXED_AMOUNT' : indicator;

    const presets =
      this.props.viewer?.transfers?.requirements
        ?.scheduledTransferAmountPresets;
    if (presets) {
      const preset = presets.find(
        // @ts-expect-error - TS2339 - Property 'indicator' does not exist on type 'Maybe<ScheduledTransferPresetOption>'.
        ({ indicator: presetIndicator }) => presetIndicator === indicator,
      );
      label = preset?.label;
    }

    return {
      schedule: {
        monthly: {
          autoPayPaymentValue,
        },
      },
      label,
    };
  };

  limitsValidation = (value: string | number | null) => {
    const transfers = this.readTransfers();
    const valueAsNum = Number(value);

    if (isNil(value) || valueAsNum === 0 || isNil(transfers?.requirements)) {
      return '';
    }

    if (
      isNotNil(transfers.requirements.maxTransferAmount) &&
      (valueAsNum > transfers.requirements.maxTransferAmount ||
        transfers.requirements.maxTransferAmount === 0)
    ) {
      return transfers.requirements.maxTransferAmountErrorMessage;
    }

    if (
      isNotNil(transfers.requirements.minTransferAmount) &&
      valueAsNum < transfers.requirements.minTransferAmount
    ) {
      return transfers.requirements.minTransferAmountErrorMessage;
    }

    return '';
  };

  renderRequirementsMessage() {
    return <PaymentDetailsMessageCard />;
  }
}

const enhancer = compose<any, any>(
  connectForm({
    form: 'transfer-instance',
  }),
  UNSAFE_connectRedux((state: AppState): any => {
    const form = state.form['transfer-instance'];

    const transferInstanceErrors = form.syncErrors;

    const { otherAmount, selectedPreset } = form.values ?? {};

    const hasAnyTransferInput = Boolean(selectedPreset) || otherAmount > 0;

    const hasValidTransferInput = isNil(transferInstanceErrors);

    const isSchedule = state.newFlows.CREATE_PAYMENT.mode === MODES.SCHEDULE;

    return {
      transferAmountFromStore: otherAmount,
      hasValidTransferInput,
      hasAnyTransferInput,
      navigate: state.routing.navigate,
      showTransactionError: hasAnyTransferInput && !hasValidTransferInput,
      isSchedule,
    };
  }),
);

export const CreateOneTimeOrScheduledPaymentForm = enhancer(
  CreateOneTimeOrScheduledPayment,
) as any;
