/* eslint-disable no-param-reassign */
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useDisclosuresProfilePageQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type { ProfileDisclosureDetailsFragment } from '~/graphql/types';
import { withoutNulls, withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';
import { isNil } from '~/utils';

import { DisclosuresForm } from './DisclosuresProfileForm';
import {
  type AccountHolderDisclosuresInput,
  type ProfileDisclosuresInput,
} from './DisclosuresProfileForm.types';

/**
 * Map backup withholding from query to mutation.
 * If `isSubjectToBackupWithholding` is null or undefined,
 * return null for all of `backupWithholding`.
 * @param backupWithholding backup withholding type from query output
 * @returns backup withholding type for mutation input
 */
const toBackupWithholdingInput = (
  backupWithholding: ProfileDisclosureDetailsFragment['backupWithholding'],
): AccountHolderDisclosuresInput['backupWithholding'] => {
  if (isNil(backupWithholding)) {
    return backupWithholding;
  }
  const { isSubjectToBackupWithholding } = backupWithholding;
  if (isNil(isSubjectToBackupWithholding)) {
    return null;
  }
  return { isSubjectToBackupWithholding };
};

/**
 * Map profile disclosures from query to mutation.
 * Map `politicalDisclosure` to `politicalExposureDisclosure`.
 * Map `backupWithholding` types.
 * @param profile profile disclosures fragment from query output
 * @returns profile disclosures fragment for mutation input
 */
const toProfileInput = (
  profile: ProfileDisclosureDetailsFragment | null | undefined,
): AccountHolderDisclosuresInput | null | undefined => {
  if (isNil(profile)) {
    return profile;
  }
  const { backupWithholding, politicalDisclosure, ...rest } = profile;
  return {
    ...rest,
    backupWithholding: toBackupWithholdingInput(backupWithholding),
    politicalExposureDisclosure: politicalDisclosure,
  };
};

export const DisclosuresProfilePage = () => {
  const { addToast } = useToast();
  const [formReady, setFormReady] = React.useState(false);
  const formMethods = useForm<ProfileDisclosuresInput>({
    defaultValues: {},
  });

  const { data, loading } = useDisclosuresProfilePageQuery({
    fetchPolicy: 'network-only',
  });

  const [updateProfile, { loading: updatingProfile }] =
    useUpdateProfileMutation();

  usePortaledSpinner(updatingProfile);

  React.useEffect(() => {
    const primary = toProfileInput(data?.viewer.profile?.primary);
    const secondary = toProfileInput(data?.viewer.profile?.secondary);

    if (primary) {
      formMethods.reset({
        primary,
        ...(secondary ? { secondary } : {}),
      });

      setFormReady(true);
    }
  }, [data, formMethods]);

  if (loading || !formReady) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.profile?.primary && !data?.viewer.profile?.secondary) {
    return (
      <GenericSystemError content="Disclosures are currently unavailable. Please try again later." />
    );
  }

  const { viewer } = data;

  const hasJointAccount = Boolean(viewer.profile?.secondary);

  const handleUpdateFor =
    (label: 'primary' | 'secondary') =>
    (update: ProfileDisclosuresInput): void => {
      const profile = update[label];

      updateProfile({
        variables: {
          input: {
            profile: withoutNulls(
              withoutTypename({
                primary: update.primary,
                ...(update.secondary ? { secondary: update.secondary } : {}),
                [label]: {
                  ...(profile ?? {}),
                },
              }),
            ),
          },
        },
        onCompleted: () =>
          addToast({
            kind: 'success',
            content: 'Profile disclosures updated',
            duration: 'long',
          }),
        onError: () =>
          addToast({
            kind: 'alert',
            content: 'Failed to update disclosures. Try again later.',
            duration: 'long',
          }),
      });
    };

  return (
    <FormProvider {...formMethods}>
      <DisclosuresForm
        accountType="primary"
        formMethods={formMethods}
        onSubmit={handleUpdateFor('primary')}
        showLabel={hasJointAccount}
      />
      {hasJointAccount && (
        <>
          <Divider />
          <DisclosuresForm
            accountType="secondary"
            formMethods={formMethods}
            onSubmit={handleUpdateFor('secondary')}
          />
        </>
      )}
    </FormProvider>
  );
};
