import * as React from 'react';
import type { FieldValues } from 'react-hook-form';

import type { MultiStepFormContextValue } from './types';

export const MultiStepFormContext =
  React.createContext<MultiStepFormContextValue<any, any> | null>(null);

export const useMultiStepFormContext = <
  T extends FieldValues,
  K extends string,
>(): MultiStepFormContextValue<T, K> => {
  const context = React.useContext(MultiStepFormContext);
  if (!context) {
    throw new Error(
      'useMultiStepFormContext must be used within a MultiStepFormProvider',
    );
  }
  return context;
};
