import { Banner } from '@m1/liquid-react';
import * as React from 'react';

import { type Pie, hasInactiveSlices } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';
import { getActivePie } from '~/redux/reducers/newFlows/reducers/portfolioEditorReducer';

export const InactiveSlicesBanner = () => {
  const pieTree: Pie | null | undefined = useSelector((state) => {
    return getActivePie(state.newFlows.PORTFOLIO_EDITOR);
  });

  const showBanner = React.useMemo<boolean>(() => {
    if (!pieTree) {
      return false;
    }

    return hasInactiveSlices(pieTree); // Note, even though the plugin says we don't need pieTree.slices
    // below in the dependency array, we do otherwise react won't recalculate
    // if a user adds or removes a slice

    /* eslint-disable-next-line */
  }, [pieTree, pieTree?.slices]);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner
      content="Remove inactive securities from your portfolio before editing or saving this Pie." // TODO update once we do Icons
      // icon={<Image alt="" src={alertIcon} />}
      kind="warning"
      maxWidth={1200}
      mt={12}
      size="wide"
    />
  );
};
