import type { SagaIterator } from 'redux-saga';
import { call, fork, put, spawn } from 'redux-saga/effects';

import {
  CreateTransferInstanceDocument,
  type CreateTransferInstanceMutationResult,
  CreateTransferInstanceRefetchDocument,
  CreateTransferLogSagaDocument,
  type CreateTransferLogSagaQueryResult,
  GenerateIdempotencyKeyDocument,
  type GenerateIdempotencyKeyMutationResult,
  SetScheduledTransferRuleDocument,
  type SetScheduledTransferRuleMutationResult,
  SetScheduledTransferRuleRefetchDocument,
} from '~/graphql/hooks';
import type {
  CreateTransferInstanceErrorEnum,
  CreateTransferInstanceInput,
  CreateTransferInstanceMutation,
  GenerateIdempotencyKeyInput,
  SetScheduledTransferRuleInput,
} from '~/graphql/types';
import {
  hideLoadingSpinner,
  setTransferIdempotencyKey,
  showLoadingSpinner,
} from '~/redux/actions';
import {
  CREATE_TRANSFER_FLOW_MODES as MODES,
  CREATE_PAYMENT_FLOW_STEPS as STEPS,
} from '~/static-constants';
import type { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../../apolloMutationSaga';
import { apolloQuerySaga } from '../../apolloQuerySaga';
import { getAnalyticsReporter, getLoggers } from '../../common';
import { changeStep, makeFlowFuncs } from '../utils';

const { selectFlowState, takeFlow, takeFlowStep } =
  makeFlowFuncs('CREATE_PAYMENT');

export function* createTransferSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginCreatePaymentFlow);
}

function* beginCreatePaymentFlow(action: any): SagaIterator<void> {
  yield fork(takeFlowStep, STEPS.SETUP_PAYMENT, finishedTransferSetup);
  yield fork(takeFlowStep, STEPS.CONFIRM_PAYMENT, finishedConfirmTransfer);
  yield fork(
    takeFlowStep,
    STEPS.CONFIRM_AUTOPAY_CREATION,
    finishedConfirmTransferSchedule,
  );
  yield fork(
    takeFlowStep,
    STEPS.SHOW_RECEIPT,
    finishedShowReceipt,
    action.payload.onFinish,
  );
  try {
    const { data }: GenerateIdempotencyKeyMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: GenerateIdempotencyKeyDocument,
        variables: { input: {} satisfies GenerateIdempotencyKeyInput },
      },
    );
    yield put(
      setTransferIdempotencyKey(
        data?.generateIdempotencyKey?.outcome?.idempotencyKey,
      ),
    );
  } catch (e: any) {
    // currently transfers don't require an idempotent key, this will not block transfer creation
    const { sentry } = yield call(getLoggers);
    sentry.message('generateIdempotencyKey mutation failed', {
      rawError: e,
    });
  }
}

function* finishedTransferSetup(): SagaIterator<void> {
  const mode: ValueOf<typeof MODES> = yield call(selectFlowState, 'mode');

  if (mode === MODES.SCHEDULE) {
    yield call(changeStep, STEPS.CONFIRM_AUTOPAY_CREATION);
  } else {
    // If a user is in a transfer that isn't an unscheduled
    // IRA distribution then we don't want to send excess
    // contribution in the request.
    yield put({
      type: 'CLEAR_EXCESS_CONTRIBUTION_DATA',
    });
    yield call(changeStep, STEPS.CONFIRM_PAYMENT);
  }
}

function* finishedConfirmTransfer() {
  function* onFinish() {
    yield call(changeStep, STEPS.SHOW_RECEIPT);
  }
  // @ts-expect-error - TS2769 - No overload matches this call.
  yield call(confirmTransfer, onFinish);
}

function* finishedConfirmTransferSchedule(): SagaIterator<void> {
  function* onFinish() {
    yield call(changeStep, STEPS.SHOW_RECEIPT);
  }
  yield call(confirmTransferSchedule, onFinish);
}

function* setTransferAmount(): SagaIterator<number> {
  const amount = yield call(selectFlowState, 'input.amount');
  return amount;
}

function* setIdempotencyKey(): SagaIterator<string> {
  return yield call(selectFlowState, 'idempotencyKey');
}

function* createTransferInstance(
  input: CreateTransferInstanceInput,
  additionalInput: Partial<CreateTransferInstanceInput> | null | undefined,
): SagaIterator<CreateTransferInstanceMutation | null | undefined> {
  const mutationInput: CreateTransferInstanceInput = {
    amount: yield call(setTransferAmount),
    fromParticipantId: input.fromParticipantId,
    toParticipantId: input.toParticipantId,
    idempotencyKey: yield call(setIdempotencyKey),
    ...additionalInput,
  };

  const { data }: CreateTransferInstanceMutationResult = yield call(
    apolloMutationSaga,
    {
      mutation: CreateTransferInstanceDocument,
      variables: {
        input: mutationInput,
      },
      refetchQueries: [{ query: CreateTransferInstanceRefetchDocument }],
    },
  );

  return data;
}

function* confirmTransfer(
  onFinish: (...args: Array<any>) => any,
  additionalInput: Partial<CreateTransferInstanceInput> | null | undefined,
): SagaIterator<void> {
  const input = yield call(selectFlowState, 'input');

  try {
    yield put(showLoadingSpinner());

    const data = yield call(createTransferInstance, input, additionalInput);
    const outcome = data.createTransferInstance.outcome;

    yield put({
      type: 'TRANSFER_INSTANCE_CREATED',
      payload: {
        outcome,
      },
    });

    const transferInstanceId = outcome?.instance?.id as string;
    yield spawn(logAnalyticsEvent, transferInstanceId);
    yield call(onFinish);
  } catch (e: any) {
    yield spawn(logCreateTransferInstanceError, e.message);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* confirmTransferSchedule(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  const input: SetScheduledTransferRuleInput = yield call(
    selectFlowState,
    'input',
  );
  const { analytics } = yield call(getLoggers);

  try {
    yield put(showLoadingSpinner());
    const { data }: SetScheduledTransferRuleMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: SetScheduledTransferRuleDocument,
        variables: {
          input: {
            // For AutoPay schedules the amount is null (if no fixed value is specified)
            // so we fallback to zero (since a number is expected by Lens)
            amount: input.amount || 0,
            fromParticipantId: input.fromParticipantId,
            schedule: input.schedule,
            toParticipantId: input.toParticipantId,
            scheduledTransferRuleId: input.scheduledTransferRuleId,
          } satisfies SetScheduledTransferRuleInput,
        },
        refetchQueries: [
          {
            query: SetScheduledTransferRuleRefetchDocument,
          },
        ],
      },
    );
    const transferRuleId = data?.setScheduledTransferRule.outcome?.rule
      ?.id as string;
    yield put({
      type: 'SCHEDULED_TRANSFER_RULE_SET',
      payload: {
        outcome: data?.setScheduledTransferRule.outcome,
      },
    });
    analytics.recordEvent('m1_invest_schedule_recurring_deposit_success');
    yield spawn(logAnalyticsEvent, transferRuleId);
    yield call(onFinish);
  } catch (e: any) {
    yield call(changeStep, STEPS.SETUP_PAYMENT);
    analytics.recordEvent('m1_invest_schedule_recurring_deposit_failed');
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedShowReceipt(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish);
}

function* logAnalyticsEvent(nodeId: string): SagaIterator<void> {
  const analytics = yield call(getAnalyticsReporter);

  try {
    const { data }: CreateTransferLogSagaQueryResult = yield call(
      apolloQuerySaga,
      {
        query: CreateTransferLogSagaDocument,
        variables: {
          nodeId,
        },
      },
    );
    const result = data?.node;
    let eventName;
    if (result?.__typename === 'TransferInstance') {
      if (result.from && result.from.transferParticipantType === 'INVEST') {
        eventName = 'achImmediateWithdrawalCreated';
      }
    } else if (result?.__typename === 'ScheduledTransferRule') {
      if (result.from && result.from.transferParticipantType === 'INVEST') {
        eventName = 'achScheduleWithdrawalCreated';
      }
    }

    if (eventName) {
      yield call([analytics, 'mutation'], 'funding', eventName);
    }
  } catch (e: any) {
    // Just do nothing if this fails.
  }
}

function* logCreateTransferInstanceError(
  e: CreateTransferInstanceErrorEnum,
): SagaIterator<void> {
  const analytics = yield call(getAnalyticsReporter);
  let eventName;
  if (e === 'AMOUNT_REQUIRES_MARGIN_PAY_BACK') {
    eventName = 'achWithdrawalDeniedUntilPayBack';
  }

  if (eventName) {
    yield call([analytics, 'mutation'], 'funding', eventName);
  }
}
