import { type LayoutableProps, Banner } from '@m1/liquid-react';
import * as React from 'react';

import type { Slice } from '~/pie-trees';

export const SliceListAllocationFeedbackCard = ({
  slices,
  ...rest
}: LayoutableProps & {
  slices: ReadonlyArray<Slice>;
}) => {
  const { content, kind } = React.useMemo(() => {
    const total = slices.reduce((p, v) => p + v.percentage, 0);

    if (total < 100) {
      return {
        content: `Add ${100 - total}% to total 100%`,
        kind: 'alert',
      };
    } else if (total > 100) {
      return {
        content: `Remove ${total - 100}% to total 100%`,
        kind: 'alert',
      };
    }

    return {
      content: `Total 100% of 100%`,
      kind: 'information',
    };
  }, [slices]);

  return slices.length ? (
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'BannerKind'.
    <Banner {...rest} content={content} kind={kind} size="inline" />
  ) : null;
};
