import pick from 'lodash-es/pick';

import {
  type RecurrenceScheduleInput,
  type CreateTransferInstanceOutcomeFragment,
  type SetScheduledTransferRuleOutcomeFragment,
} from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import {
  CREATE_TRANSFER_FLOW_MODES as MODES,
  CREATE_PAYMENT_FLOW_STEPS as STEPS,
} from '~/static-constants';

import type { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type CreatePaymentInput = {
  fromParticipantId: Maybe<string>;
  toParticipantId: Maybe<string>;
  amount: Maybe<number>;
  otherAmount: Maybe<string>;
  schedule: Maybe<RecurrenceScheduleInput>;
  isPersonalLoanPayment: boolean;
  label: Maybe<string>;
};

export type CreatePaymentFlowState = FlowState<
  string,
  {
    basePath: string;
    createdNodeId: string | null | undefined;
    idempotencyKey: string | null | undefined;
    input: CreatePaymentInput;
    mode: ValueOf<typeof MODES> | null;
    outcome:
      | (
          | CreateTransferInstanceOutcomeFragment
          | SetScheduledTransferRuleOutcomeFragment
        )
      | null
      | undefined;
  }
>;

export const createPaymentFlowInitialState: CreatePaymentFlowState = {
  basePath: '',
  createdNodeId: null,
  idempotencyKey: null,
  input: {
    fromParticipantId: null,
    toParticipantId: null,
    otherAmount: '',
    amount: null,
    isPersonalLoanPayment: false,
    label: '',
    schedule: null,
  },
  mode: null,
  outcome: null,
  step: STEPS.SETUP_PAYMENT,
  stepTitle: '',
};

const stepReducer = createStepReducer(createPaymentFlowInitialState);

function reducer(
  state: CreatePaymentFlowState = createPaymentFlowInitialState,
  action: any,
): CreatePaymentFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...createPaymentFlowInitialState,
        ...pick(action.payload, Object.keys(createPaymentFlowInitialState)),
        step: createPaymentFlowInitialState.step,
      };
    case 'TRANSFER_INSTANCE_CREATED':
      return {
        ...state,
        createdNodeId: action.payload.outcome.instance.id,
        outcome: action.payload.outcome,
      };
    case 'SCHEDULED_TRANSFER_RULE_SET':
      return {
        ...state,
        createdNodeId: action.payload.outcome.rule.id,
        outcome: action.payload.outcome,
      };
    case ACTIONS.SET_TRANSFER_IDEMPOTENCY_KEY:
      return {
        ...state,
        idempotencyKey: action.payload,
      };
    case ACTIONS.FINISHED_FLOW_STEP:
      switch (action.meta.step) {
        case STEPS.SETUP_PAYMENT:
          return {
            ...state,
            input: action.payload,
          };
        default:
          return state;
      }
    case ACTIONS.END_FLOW:
      return createPaymentFlowInitialState;
    case ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    case ACTIONS.SWITCHED_MODES:
      return {
        ...state,
        mode:
          action.payload ??
          (state.mode === MODES.ONE_TIME ? MODES.SCHEDULE : MODES.ONE_TIME),
      };
    default:
      return state;
  }
}

export const createPayment = createFlowReducer('CREATE_PAYMENT', reducer);
