import { css, LS, styled, Text, type Theme } from '@m1/liquid-react';

import type { SelectProps } from '~/toolbox/Dropdown';
import type { OptionProps } from '~/toolbox/Dropdown/Option';

type StyledOptionElementProps = Pick<OptionProps, 'isFocused' | 'selectProps'>;

export const StyledText = styled(Text)<StyledOptionElementProps>`
  color: ${(props) => {
    if (props.isFocused) {
      return props.selectProps.appTheme.colors.foregroundNeutralMain;
    }
    return props.selectProps.appTheme.colors.foregroundNeutralMain;
  }};
`;

export const StyledContent = styled.div<StyledOptionElementProps>`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background-color: ${(props) =>
    props.isFocused
      ? props.selectProps.appTheme.colors.backgroundNeutralMain
      : undefined};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledLabel = styled(LS)<
  Pick<SelectProps, 'error'> & {
    appTheme: Theme;
    backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
    isLabelForDestination?: boolean;
    isDisabled?: boolean;
    isFocused?: boolean;
  }
>`
  ${({ isLabelForDestination }) =>
    isLabelForDestination
      ? css`
          left: 1px;
          padding-left: 16px;
          min-width: 64px;
        `
      : css`
          left: 12px;
          padding: 0 4px;
        `}
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.backgroundNeutralSecondary};
  line-height: 1.5rem;
  position: absolute;
  top: 1px;
  z-index: 1;
  font-weight: ${({ error }) => error && 600};
  color: ${({ error, isDisabled, isFocused, theme }) => {
    if (isDisabled) {
      return theme.colors.foregroundNeutralTertiary;
    }
    if (error) {
      return theme.colors.critical;
    }
    if (isFocused) {
      return theme.colors.primary;
    }
    return theme.colors.foregroundNeutralSecondary;
  }};
`;
