import { Flex, HXXS, Box, PL, styled } from '@m1/liquid-react';
import React from 'react';

import { useCryptoEligibilityQuery } from '~/graphql/hooks';
import type { ScreenerCryptoFragment } from '~/graphql/types';
import { Spinner } from '~/toolbox/spinner';
import { Table } from '~/toolbox/table';
import { formatNumber } from '~/utils';

import { CryptoResultsHeader } from './CryptoResultsHeader';
import { CryptoResultsRow } from './CryptoResultsRow';
import { useCryptoScreenerResult } from './useCryptoScreenerResult';

const ResultsTable = styled(Table as any)`
  table-layout: fixed;

  thead::after {
    z-index: -1;
  }

  th {
    position: relative;
    padding-bottom: 0 !important;
  }
  margin-bottom: 64px;
`;
export const CryptoResultsTable = () => {
  const {
    queryResult: { data, loading },
  } = useCryptoScreenerResult();

  const { data: viewerData } = useCryptoEligibilityQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.crypto?.screenCrypto) {
    return null;
  }

  const screenCrypto = data.viewer.crypto.screenCrypto;

  const results = screenCrypto?.edges;

  const hasCryptoAccount = viewerData?.viewer.crypto?.hasCryptoAccount;
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" p="1rem 0">
        <Flex alignItems="baseline">
          <HXXS fontWeight={300} content="Results" />
          <Box as="span" pl={8}>
            {!screenCrypto.total ? (
              <Spinner radius={24} thickness={3} />
            ) : (
              <PL
                color="foregroundNeutralMain"
                content={`${formatNumber(screenCrypto.total, '0,0')} total`}
              />
            )}
          </Box>
        </Flex>
      </Flex>
      <ResultsTable>
        <Table.Head fixed>
          <CryptoResultsHeader hasCryptoAccount={hasCryptoAccount} />
        </Table.Head>
        <Table.Body
          emptyMessage="No results to display!"
          dataTestId="crypto-results-table-body"
        >
          {results?.map(
            (crypto, i) =>
              crypto?.node && (
                <CryptoResultsRow
                  crypto={
                    // This typing needs to be fixed in the custom useCryptoScreenerResult hook,
                    // but we can safely say this is fine based on SliceableFragment being spread in the query.
                    crypto?.node as
                      | ScreenerCryptoFragment['node']
                      | null
                      | undefined
                  }
                  key={i}
                  hasCryptoAccount={hasCryptoAccount}
                />
              ),
          ) ?? null}
        </Table.Body>
      </ResultsTable>
    </>
  );
};
