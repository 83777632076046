import {
  Box,
  Button,
  Flex,
  HXS,
  PL,
  PM,
  PXL,
  styled,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useAcceptOfferQuery } from '~/graphql/hooks';
import type { AppPillKind } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { Checkbox } from '~/toolbox/checkbox';
import { Link } from '~/toolbox/link';
import { List } from '~/toolbox/list';
import { Pill, type PillKind } from '~/toolbox/Pill';
import { formatNumber } from '~/utils';

const mapToPillKind = (kind: AppPillKind | null | undefined): PillKind => {
  switch (kind) {
    case 'CAUTION':
      return 'caution';
    case 'DANGER':
      return 'danger';
    case 'DANGER_INVERSE':
      return 'danger';
    case 'IMPORTANT':
      return 'important';
    case 'IMPORTANT_INVERSE':
      return 'important';
    case 'SUCCESS':
      return 'success';
    case 'WHATS_NEW':
      return 'new';
    case 'WHATS_NEW_INVERSE':
      return 'new';
    default:
      return 'neutral';
  }
};

type AcceptFooterProps = {
  buttonLabel: string;
  content: string;
  onFinishStep: () => void;
};

const AcceptFooter = ({
  buttonLabel,
  content,
  onFinishStep,
}: AcceptFooterProps) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  return (
    <Box mt={16} ml={14}>
      <Flex alignItems="flex-start" mb={16}>
        <Checkbox
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          size="medium"
        />
        <PL ml={12} content={content} />
      </Flex>
      <Button
        kind="primary"
        size="large"
        label={buttonLabel}
        disabled={!isChecked}
        type="submit"
        onClick={onFinishStep}
      />
    </Box>
  );
};

type AcceptOfferProps = {
  onFinishStep: () => void;
};

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
const LimitFlex = (props) => (
  <Flex {...props} justifyContent="space-between" my={2} />
);

const StyledList = styled(List)`
  list-style-type: none;
`;

const StyledListHeading = styled(PXL)`
  display: inline list-item;
  vertical-align: text-bottom;
  margin-left: 12px;
  font-weight: 600;
`;

const StyledListHeadingIcon = styled(AppImage)`
  vertical-align: sub;
`;

const StyledHeading = styled(HXS)`
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.foregroundNeutralOnDark};
`;

const StyledErrorMessage = styled(PM)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.critical};
  vertical-align: text-top;
  margin-left: 8px;
`;

const StyledErrorIcon = styled(AppImage)`
  padding-bottom: 3px;
  vertical-align: middle;
`;

const StyledOfferDetails = styled(PM)`
  font-weight: 600;
`;

export const AcceptOffer = ({ onFinishStep }: AcceptOfferProps) => {
  const [isCardholderAgreementOpened, setIsCardholderAgreementOpened] =
    React.useState<boolean>(false);

  const [showCardholderAgreementError, setShowCardholderAgreementError] =
    React.useState<boolean>(false);

  const handleFinish = () => {
    if (isCardholderAgreementOpened) {
      onFinishStep();
    } else {
      setShowCardholderAgreementError(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const { data, loading } = useAcceptOfferQuery();

  if (loading) {
    return null;
  }

  if (
    !data?.viewer.credit?.activeOffer ||
    !data.viewer.credit.onboardingContent?.applicationStatusScreen
  ) {
    return <GenericSystemError />;
  }

  const { activeOffer } = data.viewer.credit;
  const {
    additionalContent,
    buttonLabel,
    footer,
    header,
    stepOne,
    stepTwo,
    stepThree,
  } = data.viewer.credit.onboardingContent.applicationStatusScreen;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      pb={45}
      justifyContent="center"
    >
      <Box width={554} pt={160}>
        <Flex justifyContent="center" mb={32}>
          {header ? <StyledHeading>{header}</StyledHeading> : null}
        </Flex>
        <StyledList ordered>
          <List.Item>
            {stepOne?.icon ? (
              <StyledListHeadingIcon appImage={stepOne.icon} />
            ) : null}
            <StyledListHeading width={550} mt={22} content={stepOne?.title} />
            <Box ml={32} mb={32}>
              <PL width={518} mt={16}>
                {stepOne?.body}
              </PL>
              <Flex justifyContent="center">
                <Card
                  backgroundColor="backgroundNeutralMain"
                  p={16}
                  textAlign="center"
                  width={352}
                  mt={16}
                >
                  <Flex
                    color="foregroundNeutralMain"
                    font="LS"
                    flexDirection="column"
                  >
                    <LimitFlex>
                      <PM content="Credit limit" />
                      <StyledOfferDetails
                        color="foregroundNeutralMain"
                        content={
                          typeof stepOne?.details?.creditLimit === 'number'
                            ? formatNumber(stepOne.details.creditLimit, '$0,0')
                            : '$--'
                        }
                      />
                    </LimitFlex>
                    <LimitFlex>
                      <PM content="Purchase APR" />
                      <StyledOfferDetails
                        color="foregroundNeutralMain"
                        content={
                          typeof stepOne?.details?.annualPercentageRate ===
                          'number'
                            ? `${formatNumber(
                                stepOne.details.annualPercentageRate,
                                '0.00',
                              )}%`
                            : '--'
                        }
                      />
                    </LimitFlex>
                  </Flex>
                </Card>
              </Flex>
            </Box>
          </List.Item>
          <List.Item>
            {stepTwo?.icon ? (
              <StyledListHeadingIcon appImage={stepTwo.icon} />
            ) : null}
            <StyledListHeading width={550} mt={32} content={stepTwo?.title} />
            <Box ml={32} mb={28}>
              <Box mt={16} width={550}>
                <Flex alignItems="flex-start" pb={16}>
                  {activeOffer.cardholderAgreement?.url && (
                    <Link
                      to={activeOffer.cardholderAgreement.url}
                      target="_blank"
                      onClick={() => {
                        setIsCardholderAgreementOpened(
                          !isCardholderAgreementOpened,
                        );
                        setShowCardholderAgreementError(false);
                      }}
                    >
                      {activeOffer.cardholderAgreement.title}
                    </Link>
                  )}
                  {stepTwo?.viewedPill?.kind &&
                    stepTwo.notViewedPill?.kind &&
                    stepTwo.viewedPill.label &&
                    stepTwo.notViewedPill.label && (
                      <Pill
                        kind={
                          isCardholderAgreementOpened
                            ? mapToPillKind(stepTwo.viewedPill.kind)
                            : mapToPillKind(stepTwo.notViewedPill.kind)
                        }
                        label={
                          isCardholderAgreementOpened
                            ? stepTwo.viewedPill.label
                            : stepTwo.notViewedPill.label
                        }
                        ml={8}
                      />
                    )}
                </Flex>
              </Box>
              {showCardholderAgreementError &&
              stepTwo?.errorMessage &&
              stepTwo.errorIcon ? (
                <Flex>
                  <StyledErrorIcon appImage={stepTwo.errorIcon} />
                  <StyledErrorMessage>
                    {stepTwo.errorMessage}
                  </StyledErrorMessage>
                </Flex>
              ) : null}
            </Box>
          </List.Item>

          <List.Item>
            {stepThree?.icon ? (
              <StyledListHeadingIcon appImage={stepThree.icon} />
            ) : null}
            <StyledListHeading width={550} mt={22} content={stepThree?.title} />

            <Box mt={16} ml={32}>
              <Flex flexWrap="wrap" flexDirection="column">
                {stepThree?.termsAndConditions?.documents.map((d) => (
                  <Flex key={d.url} mb={6}>
                    <Link to={d.url} target="_blank">
                      {d.title}
                    </Link>
                  </Flex>
                ))}
              </Flex>
            </Box>
            <Box pt={32}>
              <PL mb={32}>{additionalContent}</PL>
            </Box>
          </List.Item>
        </StyledList>
        <Box>
          <AcceptFooter
            content={footer}
            buttonLabel={buttonLabel}
            onFinishStep={handleFinish}
          />
        </Box>
      </Box>
    </Flex>
  );
};
