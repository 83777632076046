import { Box } from '@m1/liquid-react';
import * as React from 'react';

import type { AgreementBundle } from '~/graphql/types';
import { Link, type LinkProps } from '~/toolbox/link';

export type AgreementBundleLinkListProps = {
  agreementBundle: Pick<AgreementBundle, 'documents'>;
  kind?: LinkProps['kind'];
};

export const AgreementBundleLinkList = ({
  agreementBundle,
  kind,
}: AgreementBundleLinkListProps) => {
  const { documents } = agreementBundle;
  return (
    <>
      {documents.map((doc) => (
        <Box mt={4} key={doc.url}>
          <Link to={doc.url} kind={kind} target="_blank">
            {doc.title}
          </Link>
          <br />
        </Box>
      ))}
    </>
  );
};
