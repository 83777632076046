import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  OpenAccountDocument,
  type OpenAccountMutationResult,
} from '~/graphql/hooks';
import type {
  OpenAccountInput,
  OpenAccountMutation,
  OpenAccountMutationVariables,
} from '~/graphql/types';
import { INVEST_ACCOUNT_TYPES } from '~/static-constants';

import { apolloMutationSaga } from '../apolloMutationSaga';

import { transformHolderInput } from './updateUserProfile';

export function* openAccount(
  input: OpenAccountInput,
  phone: string | null | undefined,
): SagaIterator<OpenAccountMutation['openAccount'] | null | undefined> {
  const { primary, registration, secondary } = input;

  const mutationInput: OpenAccountMutationVariables['input'] = {
    beneficiarySsns: input.beneficiarySsns,
    eSigned: input.eSigned,
    primarySsn: input.primarySsn,
    rootPieId: input.rootPieId,
    secondarySsn: input.secondarySsn,
    signature: input.signature,
    suitability: input.suitability,
    transferInfoOptIn: input.transferInfoOptIn,
    trustedContact: input.trustedContact,
    registration: input.registration || INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE,
    // @ts-ignore - schema updates needed for citizenship
    primary: primary ? transformHolderInput({ ...primary }, phone) : null,
    // @ts-ignore - schema updates needed for citizenship
    secondary:
      registration === INVEST_ACCOUNT_TYPES.JOINT_TAXABLE && secondary
        ? transformHolderInput({ ...secondary }, phone)
        : null,
  };

  const result: OpenAccountMutationResult = yield call(apolloMutationSaga, {
    mutation: OpenAccountDocument,
    variables: {
      input: mutationInput,
    } satisfies OpenAccountMutationVariables,
  });

  return result.data?.openAccount;
}
