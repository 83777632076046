import { Box, Flex, PL, PM, PXL, styled, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import {
  AccountTradingStatusDocument,
  useSetPreferredTradeWindowMutation,
} from '~/graphql/hooks';
import type {
  PreferredTradeWindowEnum,
  TradingStatusFragment,
  TradingWindowTypeEnum,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Radio } from '~/toolbox/radio';
import { CashFormatter } from '~/utils';

const StyledFlex = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
`;

export type AccountTradingWindowPreferenceProps = {
  preferredTradeWindow: PreferredTradeWindowEnum | TradingWindowTypeEnum | null;
  trading: TradingStatusFragment | null | undefined;
};

export const AccountTradingWindowPreference = ({
  preferredTradeWindow,
  trading,
}: AccountTradingWindowPreferenceProps) => {
  const { addToast } = useToast();
  const analytics = useAnalytics();
  const accountId = useSelector((state) => state.global.activeAccountId);
  const [setPreferredWindow] = useSetPreferredTradeWindowMutation();

  const setPreference = (preference: PreferredTradeWindowEnum) => {
    setPreferredWindow({
      variables: {
        input: {
          accountId: accountId as string,
          applyToAllAccounts: true,
          preferredTradeWindow: preference,
        },
      },
      refetchQueries: [
        {
          query: AccountTradingStatusDocument,
          variables: {
            accountId,
          },
        },
      ],
      onCompleted: () => {
        addToast({
          content: 'Success! Your settings have been updated.',
          kind: 'success',
          duration: 'short',
        });

        analytics.recordAppAnalyticsEvent({
          name: 'm1_invest_trade_window_edited',
          valueParameter: null,
          customParameters: [
            {
              name: 'preference',
              value: preference,
            },
          ],
          customBoolParameters: [],
          customNumberParameters: [],
        });
      },
      onError: () => {
        addToast({
          content:
            'Something went wrong with your request. Please try again later or contact support.',
          kind: 'alert',
          duration: 'short',
        });
      },
      optimisticResponse: {
        setPreferredTradeWindow: {
          __typename: 'SetPreferredTradeWindowPayload',
          didSucceed: true,
          outcome: {
            __typename: 'SetPreferredTradeWindowOutcome',
            account: {
              id: accountId as string,
              preferredTradeWindow: preference,
              __typename: 'Account',
            },
          },
        },
      },
    });
  };

  const {
    minAccountValueForMultipleDailyWindows,
    hasAccessToAndCanTradeInNextAfternoonWindow,
  } = trading ?? {};

  // User needs a certain account value to be eligible for afternoon trade window.
  const minValue =
    typeof minAccountValueForMultipleDailyWindows === 'number'
      ? CashFormatter.format(minAccountValueForMultipleDailyWindows)
      : 'a certain value';

  const afternoonRadioButtonColor = !hasAccessToAndCanTradeInNextAfternoonWindow
    ? 'foregroundNeutralTertiary'
    : 'foregroundNeutralMain';

  return (
    <StyledFlex pt={16} flexDirection="column">
      <Flex my={24} justifyContent="space-between" alignItems="center">
        <Flex>
          <PXL content="Trade window preference" />
          <Flex mt={2}>
            <Tooltip
              placement="top-start"
              icon="tooltip20"
              iconColor="foregroundPrimary"
              body={
                <PM
                  content="Changing this setting will modify trade window preference on all accounts."
                  py={8}
                  px={12}
                />
              }
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Radio.Group
          value={preferredTradeWindow}
          onChange={(value) => setPreference(value)}
        >
          <Radio.Choice
            checked={preferredTradeWindow === 'DEFAULT'}
            label={
              <PL
                color="foregroundNeutralMain"
                content="Next available trade window"
              />
            }
            value="DEFAULT"
          />
          <Radio.Choice
            checked={preferredTradeWindow === 'MORNING'}
            label={
              <PL color="foregroundNeutralMain" content="Morning window only" />
            }
            value="MORNING"
          />
          <Radio.Choice
            disabled={!hasAccessToAndCanTradeInNextAfternoonWindow}
            css={{
              margin: '10px !important',
            }}
            checked={preferredTradeWindow === 'AFTERNOON'}
            value="AFTERNOON"
            label={
              <Flex alignItems="center">
                <PL
                  color={afternoonRadioButtonColor}
                  content="Afternoon window only"
                />
                {!hasAccessToAndCanTradeInNextAfternoonWindow && (
                  <Box mt={4}>
                    <Tooltip
                      placement="top-start"
                      body={`Invest accounts with less than ${minValue} can only trade once per day. The afternoon trade window is not available today because this account already traded in the morning.`}
                    />
                  </Box>
                )}
              </Flex>
            }
          />
        </Radio.Group>
      </Flex>
    </StyledFlex>
  );
};
