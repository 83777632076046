import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useProfileSuitabilityQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type { ProfileSuitabilitiesFragment } from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import {
  InvestmentExperienceEnumDropdownOptions,
  RiskToleranceEnumDropdownOptions,
  TimeHorizonEnumDropdownOptions,
  LiquidityNeedsEnumDropdownOptions,
} from '~/static-constants/dropdown-options/suitability-dropdown-options';
import { useToast } from '~/toasts';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';
import { getEnumEntries } from '~/utils';

export const SuitabilityProfilePage = () => {
  const { data, loading, refetch } = useProfileSuitabilityQuery();
  const { addToast } = useToast();
  const [updateProfile, { loading: updatingProfile }] =
    useUpdateProfileMutation();
  const formMethods = useForm<ProfileSuitabilitiesFragment>({
    defaultValues: {},
  });

  React.useEffect(() => {
    if (!loading && data?.viewer?.profile?.suitability) {
      formMethods.reset(data.viewer.profile.suitability);
    }
  }, [data, formMethods, loading]);

  usePortaledSpinner(updatingProfile);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer?.profile) {
    return (
      <GenericSystemError content="Your profile is currently unavailable." />
    );
  }

  const handleSubmit = async (
    suitabilities: ProfileSuitabilitiesFragment,
  ): Promise<void> => {
    await updateProfile({
      variables: {
        input: {
          profile: withoutTypename({
            primary: {},
            suitability: {
              ...suitabilities,
            },
          }),
        },
      },
      onCompleted: () => {
        addToast({
          content: 'Your profile has been updated.',
          kind: 'success',
          duration: 'long',
        });
        refetch();
      },
      onError: () =>
        addToast({
          content:
            'There was an error updating your profile. Please try again later or contact support.',
          kind: 'alert',
          duration: 'long',
        }),
    });
  };

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
      <Grid.Row>
        <Grid.Col xs={12} sm={6}>
          <ControlledDropdown
            name="investmentExperience"
            label="Investment experience"
            control={formMethods.control}
            source={InvestmentExperienceEnumDropdownOptions}
            rules={{ required: 'Required' }}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <ControlledInput
            name="annualIncomeAmount"
            label="Annual income"
            control={formMethods.control}
            maskType="money"
            mask={{
              decimalScale: 0,
            }}
            rules={{ required: 'Required' }}
            maxLength={11}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <ControlledDropdown
            name="totalNetWorth"
            label="Net worth"
            control={formMethods.control}
            source={getEnumEntries('TotalNetWorthEnum')}
            rules={{ required: 'Required' }}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <ControlledDropdown
            name="liquidNetWorth"
            label="Liquid net worth"
            control={formMethods.control}
            source={getEnumEntries('LiquidNetWorthEnum')}
            rules={{ required: 'Required' }}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <ControlledDropdown
            name="riskTolerance"
            label="Risk tolerance"
            control={formMethods.control}
            source={RiskToleranceEnumDropdownOptions}
            rules={{ required: 'Required' }}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <ControlledDropdown
            name="timeHorizon"
            label="Time horizon"
            control={formMethods.control}
            source={TimeHorizonEnumDropdownOptions}
            rules={{ required: 'Required' }}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <ControlledDropdown
            name="liquidityNeeds"
            label="Liquidity needs"
            control={formMethods.control}
            source={LiquidityNeedsEnumDropdownOptions}
            rules={{ required: 'Required' }}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row xsTextRight>
        <Grid.Col xs={12}>
          <Button kind="primary" type="submit" size="medium" label="Save" />
        </Grid.Col>
      </Grid.Row>
    </form>
  );
};
