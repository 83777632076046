import type {
  CreateTransferInstanceMutation,
  SetScheduledTransferRuleMutation,
} from '~/graphql/types';
import { CREATE_TRANSFER_FLOW_MODES } from '~/static-constants';

export const CREATE_TRANSFER_ACTIONS = Object.freeze({
  SWITCHED_MODES: 'SWITCHED_MODES' as const,
  SET_TRANSFER_IDEMPOTENCY_KEY: 'SET_TRANSFER_IDEMPOTENCY_KEY' as const,
  TRANSFER_INSTANCE_CREATED: 'TRANSFER_INSTANCE_CREATED' as const,
  SCHEDULED_TRANSFER_RULE_SET: 'SCHEDULED_TRANSFER_RULE_SET' as const,
});

export type ClickedSwitchModes = {
  payload: unknown;
  type: typeof CREATE_TRANSFER_ACTIONS.SWITCHED_MODES;
};

export type SetTransferIdempotencyKey = {
  payload: string | null | undefined;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_TRANSFER_IDEMPOTENCY_KEY;
};

export type CreateTransferInstance = {
  payload: {
    outcome:
      | CreateTransferInstanceMutation['createTransferInstance']['outcome']
      | null
      | undefined;
  };
  type: typeof CREATE_TRANSFER_ACTIONS.TRANSFER_INSTANCE_CREATED;
};

export type SetScheduledTransferRule = {
  payload: {
    outcome:
      | SetScheduledTransferRuleMutation['setScheduledTransferRule']['outcome']
      | null
      | undefined;
  };
  type: typeof CREATE_TRANSFER_ACTIONS.SCHEDULED_TRANSFER_RULE_SET;
};

export const clickedSwitchModes = (
  payload: ValueOf<typeof CREATE_TRANSFER_FLOW_MODES>,
): ClickedSwitchModes => ({
  payload,
  type: CREATE_TRANSFER_ACTIONS.SWITCHED_MODES,
});

export const setTransferIdempotencyKey = (
  payload: SetTransferIdempotencyKey['payload'] | null | undefined,
): SetTransferIdempotencyKey => ({
  type: CREATE_TRANSFER_ACTIONS.SET_TRANSFER_IDEMPOTENCY_KEY,
  payload,
});

export type CreateTransferAction =
  | ClickedSwitchModes
  | CreateTransferInstance
  | SetScheduledTransferRule
  | SetTransferIdempotencyKey;
