import { Card } from '@m1/liquid-react';
import * as React from 'react';

import type { TransferRequirementsTimingDescriptionCellFragment } from '~/graphql/types';

import { TimingDescriptionCell } from './TimingDescriptionCell';

export type TransferRequirementsTimingDescriptionCellProps = {
  transferRequirements: TransferRequirementsTimingDescriptionCellFragment;
};

export const TransferRequirementsTimingDescriptionCell = (
  props: TransferRequirementsTimingDescriptionCellProps,
) => {
  if (!props.transferRequirements.timingDescription) {
    return null;
  }
  return (
    <Card
      borderColor="transparent"
      mt={16}
      p="12px 16px"
      width="100%"
      backgroundColor="backgroundInfoSubtle"
    >
      <TimingDescriptionCell
        dependsOnHolds={props.transferRequirements.dependsOnHolds}
        dependsOnSells={props.transferRequirements.dependsOnSells}
        timingDescription={props.transferRequirements.timingDescription}
      />
    </Card>
  );
};
