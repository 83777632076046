import * as React from 'react';

import type { WizardContextValue } from './types';
import { WizardProviderContext } from './useWizardContext';

export const WizardProvider = <K extends string>({
  children,
  value,
}: {
  children: React.ReactNode;
  value: WizardContextValue<K>;
}) => {
  return (
    <WizardProviderContext.Provider value={value}>
      {children}
    </WizardProviderContext.Provider>
  );
};
