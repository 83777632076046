import { HXS, PL, Card } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { CreateOneTimeOrScheduledPaymentForm } from '~/forms/CreateOneTimeOrScheduledPayment';
import { useGetPaymentPreloadQuery } from '~/graphql/hooks';
import type { TransferParticipantFragment } from '~/graphql/types';
import { useSearchParams } from '~/hooks/useSearchParams';
import { clickedSwitchModes } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';
import type { DropdownGroup, DropdownOption } from '~/toolbox/Dropdown';
import { isNil } from '~/utils';

type CreatePaymentProps = {
  onFinishStep: (...args: Array<any>) => any;
};

export const SetupPaymentStep = (props: CreatePaymentProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const fromParticipantIdQueryParam = searchParams.get('fromParticipantId');
  const toParticipantIdQueryParam = searchParams.get('toParticipantId');
  const amountQueryParam = searchParams.get('amount');
  const modeQueryParam = searchParams.get('mode');

  const flowState = useSelector((state) => state.newFlows.CREATE_PAYMENT);

  const initialValues = {
    ...flowState.input,
    fromParticipantId:
      fromParticipantIdQueryParam ?? flowState.input.fromParticipantId ?? null,
    toParticipantId:
      toParticipantIdQueryParam ?? flowState.input.toParticipantId ?? null,
    otherAmount: amountQueryParam ?? flowState.input.otherAmount ?? null,
  };

  const { data } = useGetPaymentPreloadQuery({
    variables: {
      date: moment().format('YYYY-MM-DD'),
      fromParticipantId: initialValues.fromParticipantId,
      toParticipantId: initialValues.toParticipantId,
      transferType:
        modeQueryParam === MODES.SCHEDULE
          ? 'SCHEDULED_TRANSFER_RULE'
          : 'TRANSFER_INSTANCE',
    },
  });

  const requirements = data?.viewer.transfers?.requirements;

  const isCreditCardPayment = Boolean(
    requirements?.isCreditCardPaymentTransfer,
  );
  const isPersonalLoanPayment = Boolean(requirements?.isLoanPaymentTransfer);
  const isCreditAutoPayEnabled = data?.viewer.credit?.isAutoPayEnabled;
  const isPersonalLoansAutoPayEnabled =
    data?.viewer.borrow?.personalLoans?.isPersonalLoansAutoPayEnabled;

  const transferDueDate = requirements?.transferDueDateLabel;
  const handleSwitchModes = (mode: ValueOf<typeof MODES>): void => {
    dispatch(clickedSwitchModes(mode));
  };

  const { sourceParticipants, destinationParticipants } =
    data?.viewer?.transfers ?? {};

  const sourceParticipantsList = React.useMemo(() => {
    return readParticipants(sourceParticipants?.list ?? []);
  }, [sourceParticipants?.list]);

  const destinationParticipantsList = React.useMemo(() => {
    // For the payments form, we only allow credit card and loan participants:
    const filteredList = (destinationParticipants?.list ?? []).filter(
      (participant) =>
        ['CREDIT_CARD', 'LOAN'].includes(participant.transferParticipantType) &&
        participant.id === toParticipantIdQueryParam,
    );

    return readParticipants(filteredList);
  }, [toParticipantIdQueryParam, destinationParticipants?.list]);

  const isSchedule = flowState.mode === MODES.SCHEDULE;

  return (
    <>
      <HXS
        color="foregroundNeutralMain"
        content={isSchedule ? 'Set up AutoPay' : 'One-time payment'}
      />
      {(isCreditCardPayment || isPersonalLoanPayment) && (
        <PL content={transferDueDate} />
      )}
      <Card mt={32} p={32}>
        <CreateOneTimeOrScheduledPaymentForm
          destinationParticipants={destinationParticipantsList}
          initialValues={initialValues}
          isCreditAutoPayEnabled={isCreditAutoPayEnabled}
          isCreditCardPayment={isCreditCardPayment}
          isPersonalLoanPayment={isPersonalLoanPayment}
          isPersonalLoansAutoPayEnabled={isPersonalLoansAutoPayEnabled}
          toParticipantId={initialValues.toParticipantId}
          onSubmit={props.onFinishStep}
          onSwitchModes={handleSwitchModes}
          sourceParticipants={sourceParticipantsList}
          viewer={data?.viewer}
        />
      </Card>
    </>
  );
};

function readParticipants(
  participants: TransferParticipantFragment[] | null | undefined,
): DropdownGroup[] {
  if (isNil(participants)) {
    return [];
  }

  const participantsGrouped = participants.reduce(
    (agg, participant) => {
      const { transferParticipantType } = participant;
      const key =
        transferParticipantType === 'SAVE' ? 'EARN' : transferParticipantType;
      const option = {
        label: participant.transferParticipantName,
        value: participant.id,
        participant,
      };
      const list = agg[key] ?? [];
      return {
        ...agg,
        [key]: [...list, option],
      };
    },
    {} as Record<string, DropdownOption[]>,
  );

  const dropdownOptions = Object.entries(participantsGrouped).map(
    ([label, options]) => ({
      label,
      options,
    }),
  );
  return dropdownOptions;
}
