import moment from 'moment';

import type {
  AccountTaxLot,
  InvestActivityNodeFragment,
  InvestmentsFragment,
} from '~/graphql/types';
import {
  getActivitySummary,
  getActivityValue,
} from '~/pages/dashboard/invest/activity/utils';
import { formatCurrencyWithCommas } from '~/utils/formatting';

export const prepareInvestmentsForDownload = (
  investments?: InvestmentsFragment | null,
): Array<Array<string>> => {
  if (investments?.positions.edges) {
    // @ts-expect-error - TS2322 - Type '{ readonly cursor: string; readonly node: { readonly id: string; readonly cost: { readonly averageSharePrice: number; readonly cost: number; } | null; readonly marginability: { readonly maintenanceEquityRequirementPercent: number; } | null; readonly positionSecurity: { ...; }; readonly quantity: number; readonly unr...' is not assignable to type 'string[][]'. | TS2769 - No overload matches this call.
    return investments.positions.edges.reduce((acc, position) => {
      const node = position?.node;
      const positionDetails = node?.positionSecurity;
      const symbol = positionDetails?.symbol || '';
      const name = positionDetails?.descriptor || '';
      const shares = node?.quantity.toString() || '';
      const avgPrice = formatCurrencyWithCommas(
        node?.cost?.averageSharePrice.toString() || '',
      );
      const costBasis = formatCurrencyWithCommas(
        node?.cost?.cost.toString() || '',
      );
      const unrealizedGainValue = formatCurrencyWithCommas(
        node?.unrealizedGain?.gain.toString() || '',
      );
      const unrealizedGainPercentage =
        node?.unrealizedGain?.gainPercent.toString() || '';
      const value = formatCurrencyWithCommas(
        node?.value?.value.toString() || '',
      );
      return [
        ...acc,
        [
          symbol,
          name,
          shares,
          avgPrice,
          costBasis,
          unrealizedGainValue,
          unrealizedGainPercentage,
          value,
        ],
      ];
    }, []);
  }
  return [];
};

export const prepareActivityForDownload = (
  investActivity?: Array<InvestActivityNodeFragment> | null,
): Array<Array<string>> => {
  if (investActivity) {
    return investActivity.map((val) => {
      const date = moment.parseZone(val.date || '').format('ll');
      const activity = val.title || '';
      const summary = getActivitySummary(val) || '';
      const value = getActivityValue(val) || '';
      return [date, activity, summary, value];
    });
  }
  return [];
};

export const prepareToxLotsForDownload = (
  taxLotsData: AccountTaxLot[] | null | undefined,
): string[][] => {
  if (taxLotsData) {
    return taxLotsData.reduce((acc, val): any[][] => {
      const {
        symbol = '',
        cusip = '',
        acquisitionDate = '',
        quantity = '',
        costBasis = '',
        shortLongTermHolding = '',
        unrealizedGainLoss = '',
        closeDate = '',
        shortTermRealizedGainLoss = '',
        longTermRealizedGainLoss = '',
        washSaleIndicator = '',
        id = '',
      } = val;

      return [
        ...acc,
        [
          symbol,
          cusip,
          acquisitionDate,
          quantity,
          costBasis,
          shortLongTermHolding,
          unrealizedGainLoss,
          closeDate,
          shortTermRealizedGainLoss,
          longTermRealizedGainLoss,
          washSaleIndicator,
          id,
        ],
      ];
    }, [] as string[][]);
  }
  return [];
};

const processRow = (row: any[]) => {
  let finalVal = '';
  for (let j = 0; j < row.length; j++) {
    const val = row[j];

    let innerValue = val === null ? '' : val.toString();
    if (val instanceof Date) {
      innerValue = val.toLocaleString();
    }

    let result = innerValue.replace(/"/g, '""');
    if (result.search(/("|,|\n)/g) >= 0) {
      result = '"' + result + '"';
    }
    if (j > 0) {
      finalVal += ',';
    }
    finalVal += result;
  }
  return finalVal + '\n';
};

export const buildHtmlLink = (blob: Blob, filename: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    // Opts to use HTML5 download attribute on hidden link
    // Support for this attribute is shown at the following link:  https://www.w3schools.com/tags/att_a_download.asp
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

type ExportInput = {
  filename: string;
  disclaimer?: string[];
  headers: string[];
  rows?: string[][];
};

export const exportToCsv = ({
  filename,
  disclaimer,
  rows,
  headers,
}: ExportInput) => {
  let csvFile = '';
  if (disclaimer) {
    disclaimer.forEach((line) => {
      csvFile += processRow([line]);
    });

    csvFile += processRow(headers);
  } else {
    csvFile = processRow(headers);
  }
  if (!rows) {
    return;
  }
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], {
    type: 'text/csv;charset=utf-8;',
  });
  // @ts-expect-error - TS2339 - Property 'msSaveBlob' does not exist on type 'Navigator'.
  if (navigator.msSaveBlob) {
    // IE 10+
    // @ts-expect-error - TS2339 - Property 'msSaveBlob' does not exist on type 'Navigator'.
    navigator.msSaveBlob(blob, filename);
  } else {
    buildHtmlLink(blob, filename);
  }
};

export const TAX_LOTS_DOWNLOAD_DISCLAIMER = [
  `**This data is being provided for general and reference  purposes only. This report reflects current data available from prior trading day close, which may be updated for a number of reasons, including wash sales, income reclassifications, corporate actions, etc.`,
  ` M1 is required to provide accurate tax lot basis information in connection with 1099 reporting for "covered" securities at the end of each year. Your 1099 documentation will contain the most up to date information for tax reporting purposes.  Please note that 2023 closed lot data will only include closed lots post migration to M1's self clearing platform.  Closed lots prior to the migration date are available via Apex Clearing. Instructions on accessing this information can be found here: https://help.m1.com/en/articles/9331949-download-and-view-realized-gains`,
];
