import { HXXS } from '@m1/liquid-react';
import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';

import { TextField, DropdownField } from '~/forms/fields';
import {
  requiredLoanAmount,
  requiredLoanPurpose,
  requiredLoanTerm,
} from '~/forms/validators';
import { connectForm } from '~/hocs';
import { formatCurrencyWithCommas } from '~/utils/formatting';

import { Cell } from '../../components/Layout';

const warningLoanAmount = () => 'Enter an amount between $2,500 and $50,000.';

const format = (value?: number): string =>
  value ? `$${formatCurrencyWithCommas(value.toString())}` : '';

const normalize = (value?: string): number | null =>
  value ? Number(value.replace(/[^\d]/g, '')) : null;

export type LendingLoanInformationFormProps = InjectedFormProps<any> & {
  onFinishStep: () => void;
};

export const LoanInformationForm = connectForm({
  form: 'LoanInformationForm',
})(({ initialValues, handleSubmit }: LendingLoanInformationFormProps) => (
  <Cell
    as="form"
    id="loanInformationForm"
    vertical
    css="gap: 32px;"
    onSubmit={handleSubmit}
  >
    <Cell vertical css="gap: 24px;">
      <HXXS>What will you be using the loan for?</HXXS>
      <Cell as="div" css="> div { width: 50%; }">
        <DropdownField
          name="loanPurpose"
          label="Loan purpose"
          size="medium"
          placeholder="Select"
          options={initialValues.loanPurposeOptions}
          validate={[requiredLoanPurpose]}
        />
      </Cell>
    </Cell>
    <Cell vertical css="gap: 24px;">
      <HXXS>How much would you like to borrow?</HXXS>
      <Cell as="div" css="gap: 24px; > div { width: 50%; } ">
        <TextField
          name="loanAmount"
          label="Loan amount"
          format={format}
          normalize={normalize}
          validate={[requiredLoanAmount]}
          warn={warningLoanAmount}
        />
        <DropdownField
          name="loanTerm"
          label="Loan term"
          size="medium"
          placeholder="Select"
          options={initialValues.loanTermOptions}
          validate={[requiredLoanTerm]}
        />
      </Cell>
    </Cell>
  </Cell>
));
