import { Box, Flex, PS, spacingUnits } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { IRSContributionLimitsDescription } from '~/components/IRSContributionLimitsDescription';
import { useIraDistributionInfoQuery } from '~/graphql/hooks';
import type {
  IraContributionYear,
  IraDistributionInfoQuery,
  TransferParticipantDetails,
  TransferParticipantIraWithholdingFragment,
  TransferScenarioTypeEnum,
  TransferWizardParticipantDetailsFragment,
} from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { IraDistributionReasonEnumDropdownOptions } from '~/static-constants';

import { CreateTransferContext } from '../CreateTransferContext';
import type { TransferDetailsFormValues } from '../steps/TransferDetails.types';

const getContributionYearDropdownOptions = (
  contributionYears: IraContributionYear[],
) =>
  contributionYears.map((contributionYear) => ({
    name:
      contributionYear.year === new Date().getFullYear()
        ? 'CURRENT_YEAR'
        : 'PRIOR_YEAR',
    description: (
      <>{`${contributionYear.year === new Date().getFullYear() ? 'Current Year' : 'Previous Year'}`}</>
    ),
  }));

const IRAContributionFields = ({
  contributionYears,
  disableContributionYear,
}: {
  contributionYears: IraContributionYear[];
  disableContributionYear: boolean;
}) => {
  const { control, watch } = useFormContext<TransferDetailsFormValues>();
  const [isRollover, contributionYear] = watch([
    'isRollOver',
    'contributionYear',
  ]);
  const showContributionYear = isRollover === false;

  return (
    <Flex flexDirection="column" width="100%">
      <ControlledDropdown
        name="isRollOver"
        label="Is this a rollover"
        source={[
          { name: true, description: 'Yes' },
          { name: false, description: 'No' },
        ]}
        rules={{
          validate: (value) => !isNil(value) || 'This field is required',
        }}
        style={{
          marginTop: 0,
        }}
        info="Use within 60 days of distribution"
      />
      {showContributionYear && (
        <>
          <ControlledDropdown
            name="contributionYear"
            label="Contribution year"
            control={control}
            source={getContributionYearDropdownOptions(contributionYears)}
            disabled={disableContributionYear}
            rules={{
              validate: (value) => !isNil(value),
              required: {
                value: showContributionYear,
                message: 'A contribution year is required',
              },
            }}
            style={{
              marginTop: 16,
            }}
            info={
              <IRSContributionLimitsDescription
                contributionYears={contributionYears}
                fontSize={12}
                contributionYearSelected={contributionYear}
              />
            }
          />
        </>
      )}
    </Flex>
  );
};

const IRADistributionFields = ({
  contributionYears,
  iraWithholding,
}: {
  contributionYears: IraContributionYear[];
  iraWithholding: Maybe<TransferParticipantIraWithholdingFragment>;
}) => {
  const { control, watch, setValue, getFieldState } =
    useFormContext<TransferDetailsFormValues>();

  const [distributionReason, sourceId] = watch([
    'distributionReason',
    'sourceId',
  ]);
  const showContributionYear =
    distributionReason === 'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE';

  const { data } = useIraDistributionInfoQuery({
    variables: {
      accountId: sourceId as string,
    },
  });

  const account = data?.node as ExtractTypename<
    IraDistributionInfoQuery['node'],
    'Account'
  >;

  const iraDistributionReasons = account?.iraDistributionReasons;

  // Filter to only show the options that are available for the account.
  // Use enum options here rather than just using the API response because
  // 1. The response from lens does not include display copy,
  // 2. We want to use the sort order defined in the custom enum,
  // 3. Clients need to opt-in to new reasons by adding them to the enum.
  // If we didn't get reasons from the account schema, show all local options.
  const filteredOptions = iraDistributionReasons
    ? IraDistributionReasonEnumDropdownOptions.filter((option) =>
        iraDistributionReasons.includes(option.name),
      )
    : IraDistributionReasonEnumDropdownOptions;

  // Clear out fields if conditional value (`distributionReason`) no longer requires it
  React.useEffect(() => {
    if (!showContributionYear && Boolean(contributionYears)) {
      setValue('contributionYear', undefined);
      setValue('niaAmount', undefined);
    }
  }, [contributionYears, setValue, showContributionYear]);

  return (
    <Flex flexDirection="column" width="100%">
      <ControlledInput
        name="federalWithholding"
        control={control}
        label="Federal tax withholding (%)"
        defaultValue={
          iraWithholding
            ? iraWithholding.defaultFederalWithholding * 100
            : undefined
        }
        maskType="percent"
        rules={{
          required: 'This field is required',
          min: {
            value: 0,
            message: 'A value less than 0 is not allowed',
          },
          max: {
            value: 100,
            message: 'A value greater than 100 is not allowed',
          },
        }}
      />
      <ControlledInput
        name="stateWithholding"
        control={control}
        label="State tax withholding (%)"
        defaultValue={
          iraWithholding
            ? iraWithholding?.defaultStateWithholding * 100
            : undefined
        }
        maskType="percent"
        rules={{
          required: 'This field is required',
          min: {
            value: 0,
            message: 'A value less than 0 is not allowed',
          },
          max: {
            value: 100,
            message: 'A value greater than 100 is not allowed',
          },
        }}
      />
      <Box
        px={16}
        mb={16}
        mt={getFieldState('stateWithholding').invalid ? 8 : -4}
      >
        <PS color="foregroundNeutralSecondary">
          {iraWithholding?.stateWithholdingInformationText}
        </PS>
      </Box>
      <ControlledDropdown
        name="distributionReason"
        label="Distribution Reason"
        control={control}
        source={filteredOptions}
        rules={{
          required: {
            value: true,
            message: 'A distribution reason is required',
          },
        }}
        id="distributionReason"
      />
      {showContributionYear && (
        <>
          <ControlledDropdown
            name="contributionYear"
            label="Tax Year"
            control={control}
            style={{ marginBottom: 4 }}
            source={getContributionYearDropdownOptions(contributionYears)}
            rules={{
              required: {
                value: showContributionYear,
                message: 'A contribution year is required',
              },
            }}
            id="contributionYear"
          />
          <Box px={16} mb={8}>
            <PS color="foregroundNeutralSecondary">
              {data?.viewer.transfers?.niaWithholdingDisclaimer?.text}
            </PS>
          </Box>
          <ControlledInput
            name="niaAmount"
            label="Net Income Attributable"
            maskType="money"
            control={control}
            defaultValue={0}
            min={0}
            max={100000}
            helpText={data?.viewer.transfers?.niaDisclaimer?.text}
          />
        </>
      )}
      <IraLinks sourceId={sourceId} />
    </Flex>
  );
};

const IraLinks = ({
  scenario,
  sourceId,
}: {
  scenario?: TransferScenarioTypeEnum;
  sourceId: Maybe<string>;
}) => {
  const { data } = useIraDistributionInfoQuery({
    variables: {
      accountId: sourceId as string,
      scenario,
    },
    skip: !sourceId,
  });
  return (
    <Flex flexDirection="column" gap="16px" my={spacingUnits.s}>
      {data?.viewer.transfers?.formLinks?.map((link) => {
        return link && <LinkableLink underline linkable={link} />;
      })}
    </Flex>
  );
};

const IraConversionFields = ({
  iraWithholding,
}: Partial<TransferParticipantDetails>) => {
  const { control, watch, setValue, getFieldState } =
    useFormContext<TransferDetailsFormValues>();

  const [sourceId] = watch(['sourceId']);

  React.useEffect(() => {
    setValue('distributionReason', 'CONVERSION');
    setValue('contributionYear', 'CURRENT_YEAR');
  }, [setValue]);

  if (!iraWithholding) {
    return null;
  }
  return (
    <Flex flexDirection="column" width="100%">
      <ControlledInput
        name="federalWithholding"
        control={control}
        label="Federal tax withholding (%)"
        defaultValue={iraWithholding?.defaultFederalWithholding}
        maskType="percent"
        rules={{
          required: 'This field is required',
          min: {
            value: 0,
            message: 'A value less than 0 is not allowed',
          },
          max: {
            value: 100,
            message: 'A value greater than 100 is not allowed',
          },
        }}
      />
      <ControlledInput
        name="stateWithholding"
        control={control}
        label="State tax withholding (%)"
        defaultValue={iraWithholding?.defaultStateWithholding}
        maskType="percent"
        rules={{
          required: 'This field is required',
          min: {
            value: 0,
            message: 'A value less than 0 is not allowed',
          },
          max: {
            value: 100,
            message: 'A value greater than 100 is not allowed',
          },
        }}
      />
      <Box
        px={16}
        mb={16}
        mt={getFieldState('stateWithholding').invalid ? 8 : -4}
      >
        <PS color="foregroundNeutralSecondary">
          {iraWithholding?.stateWithholdingInformationText}
        </PS>
      </Box>
      <ControlledDropdown
        name="distributionReason"
        label="Distribution Reason"
        control={control}
        source={[
          {
            name: 'CONVERSION',
            description: 'Roth Conversion',
          },
        ]}
        disabled
        rules={{
          required: {
            value: true,
            message: 'A distribution reason is required',
          },
        }}
        id="distributionReason"
      />
      <ControlledDropdown
        name="contributionYear"
        label="Contribution year"
        control={control}
        source={[
          {
            name: 'CURRENT_YEAR',
            description: 'Current Year',
          },
        ]}
        disabled
        rules={{
          required: {
            value: true,
            message: 'A contribution year is required',
          },
        }}
        style={{
          marginTop: 16,
        }}
      />
      <IraLinks sourceId={sourceId} scenario="IRA_CONVERSION" />
    </Flex>
  );
};

const IraRecharacterizationFields = ({
  contributionYears,
}: {
  contributionYears: IraContributionYear[];
}) => {
  const { control, watch, setValue } =
    useFormContext<TransferDetailsFormValues>();

  const [sourceId] = watch(['sourceId']);
  const { data } = useIraDistributionInfoQuery({
    variables: {
      accountId: sourceId as string,
    },
  });

  React.useEffect(() => {
    setValue('distributionReason', 'RECHARACTERIZATION');
  }, [setValue]);
  return (
    <Flex flexDirection="column" width="100%" gap={spacingUnits.xxs}>
      <Flex flexDirection="column" gap={spacingUnits.xxs}>
        <ControlledDropdown
          name="distributionReason"
          label="Distribution Reason"
          control={control}
          source={[
            {
              name: 'RECHARACTERIZATION',
              description: 'IRA recharacterization',
            },
          ]}
          disabled
          rules={{
            required: {
              value: true,
              message: 'A distribution reason is required',
            },
          }}
          id="distributionReason"
        />
        <ControlledDropdown
          name="contributionYear"
          label="Tax Year"
          control={control}
          style={{ marginBottom: 4 }}
          source={getContributionYearDropdownOptions(contributionYears)}
          rules={{
            required: {
              value: true,
              message: 'A contribution year is required',
            },
          }}
          id="contributionYear"
        />
        <ControlledInput
          name="niaAmount"
          label="Net Income Attributable"
          maskType="money"
          control={control}
          defaultValue={0.0}
          min={0}
          max={100000}
          helpText={data?.viewer.transfers?.niaDisclaimer?.text}
        />
      </Flex>
      <IraLinks sourceId={sourceId} scenario="IRA_RECHARACTERIZATION" />
    </Flex>
  );
};

export const TransferIRAFields = ({
  sourceDetails,
  destinationDetails,
  disableContributionYear,
}: {
  sourceDetails: TransferWizardParticipantDetailsFragment | null;
  destinationDetails: TransferWizardParticipantDetailsFragment | null;
  disableContributionYear: boolean;
}) => {
  const transferContext = React.useContext(CreateTransferContext);
  const { getValues, setValue } = useFormContext<TransferDetailsFormValues>();

  const isIraConversion =
    transferContext?.scenarioQueryParam === 'IRA_CONVERSION';
  const isIraRecharacterization =
    transferContext?.scenarioQueryParam === 'IRA_RECHARACTERIZATION';
  const isDistribution =
    sourceDetails?.account?.__typename === 'Account'
      ? sourceDetails.account.isRetirement
      : false;
  const isContribution =
    destinationDetails?.account?.__typename === 'Account'
      ? destinationDetails.account.isRetirement
      : false;
  const iraWithholding =
    sourceDetails?.__typename === 'TransferParticipantDetails'
      ? sourceDetails.iraWithholding
      : null;

  const data = transferContext?.data;

  const contributionYears = React.useMemo(
    () => data?.viewer.irsRegulations.iraContributionYears ?? [],
    [data],
  );
  // If no IRA account is involved, clear out the form values
  React.useEffect(() => {
    if (isContribution || isDistribution) {
      return;
    }
    // TODO: might have to clear fields even when switching between contribution/distribution
    const haveIraValue = getValues([
      'isRollOver',
      'contributionYear',
      'distributionReason',
      'federalWithholding',
      'stateWithholding',
    ]).find((value) => !isNil(value));

    if (!isNil(haveIraValue)) {
      setValue('isRollOver', undefined);
      setValue('contributionYear', undefined);
      setValue('distributionReason', undefined);
      setValue('federalWithholding', undefined);
      setValue('stateWithholding', undefined);
    }
  }, [getValues, isContribution, isDistribution, setValue]);

  if (isIraConversion) {
    return <IraConversionFields iraWithholding={iraWithholding} />;
  }
  if (isIraRecharacterization) {
    return (
      <IraRecharacterizationFields contributionYears={contributionYears} />
    );
  }
  if (isDistribution) {
    return (
      <IRADistributionFields
        contributionYears={contributionYears}
        iraWithholding={iraWithholding}
      />
    );
  }
  if (isContribution) {
    return (
      <IRAContributionFields
        contributionYears={contributionYears}
        disableContributionYear={disableContributionYear}
      />
    );
  }
  return null;
};
