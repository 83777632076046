import { Box, Button, Flex, HM, HXXS, PL } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { buildFullName } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenProfileSection';
import { formatTrustedContactAsAnArray } from '~/flows/components/onboarding/invest/steps/InvestConfirmationStep/InvestConfirmationHelpers';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { stripPhoneNumberFormatting } from '~/forms/utils';
import {
  useInvestAccountReviewStepRequiredDocumentsQuery,
  useOpenAccountMutation,
  useUpdateFullyPaidLendingStatusMutation,
} from '~/graphql/hooks';
import type { OpenAccountInput } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import {
  hideLoadingSpinner,
  showLoadingSpinner,
  resetAllReactHookFormData,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import type { ReactHookFormState } from '~/redux/reducers/reactHookFormReducer';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import {
  buildAddress,
  formatPhoneNumber,
  getEnumDescription,
  maskSsn,
} from '~/utils';

import { InvestFullyPaidLendingDisclosure } from '../../onboarding/invest/InvestFullyPaidLendingDisclosure/InvestFullyPaidLendingDisclosure';

const prepareOpenAccountInput = (
  forms: ReactHookFormState,
): OpenAccountInput => {
  const {
    openJointInvestAccountAnnualIncome,
    openJointInvestAccountContactInfo,
    openJointInvestAccountDisclosures,
    openJointInvestAccountEmployment,
    openJointInvestAccountIdentity,
    openJointInvestAccountNetWorth,
    openJointInvestAccountInvestmentExperience,
    openJointInvestAccountLiquidityNeeds,
    openJointInvestAccountRiskTolerance,
    openJointInvestAccountSocialSecurityNumber,
    openJointInvestAccountLiquidNetWorth,
    openJointInvestAccountTrustedContact,
  } = forms;

  return {
    registration: 'JOINT',
    secondary: {
      firstName: openJointInvestAccountContactInfo?.firstName,
      lastName: openJointInvestAccountContactInfo?.lastName,
      middleInitial: openJointInvestAccountContactInfo?.middleInitial,
      suffix: openJointInvestAccountContactInfo?.suffix,
      dateOfBirth: moment(
        openJointInvestAccountIdentity?.dateOfBirth,
        'MM/DD/YYYY',
      ).format('YYYY-MM-DD'),
      employment: {
        employer: openJointInvestAccountEmployment?.employer,
        occupation: openJointInvestAccountEmployment?.occupation,
        status: openJointInvestAccountEmployment?.employmentStatus,
      },
      ...openJointInvestAccountDisclosures,
      homeAddress: {
        lineOne: openJointInvestAccountContactInfo?.homeAddress?.lineOne,
        lineTwo: openJointInvestAccountContactInfo?.homeAddress?.lineTwo,
        city: openJointInvestAccountContactInfo?.homeAddress?.city,
        stateOrProvince:
          openJointInvestAccountContactInfo?.homeAddress?.stateOrProvince,
        postalCode: openJointInvestAccountContactInfo?.homeAddress?.postalCode,
        country: 'USA',
      },
      countryOfCitizenship: openJointInvestAccountIdentity?.citizenship,
      phoneNumber: stripPhoneNumberFormatting(
        openJointInvestAccountContactInfo?.phoneNumber,
      ),
    },
    secondarySsn:
      openJointInvestAccountSocialSecurityNumber?.socialSecurityNumber.replaceAll(
        '-',
        '',
      ),
    suitability: {
      annualIncomeAmount:
        typeof openJointInvestAccountAnnualIncome?.annualIncome === 'number'
          ? openJointInvestAccountAnnualIncome?.annualIncome
          : Number(
              openJointInvestAccountAnnualIncome?.annualIncome
                ?.replace('$', '')
                .replaceAll(',', ''),
            ),
      investmentExperience:
        openJointInvestAccountInvestmentExperience.investmentExperience,
      liquidNetWorth: openJointInvestAccountLiquidNetWorth?.liquidNetWorth,
      liquidityNeeds: openJointInvestAccountLiquidityNeeds.liquidityNeeds,
      riskTolerance: openJointInvestAccountRiskTolerance.riskTolerance,
      timeHorizon: openJointInvestAccountLiquidityNeeds?.timeHorizon,
      totalNetWorth: openJointInvestAccountNetWorth?.totalNetWorth,
    },
    trustedContact: openJointInvestAccountTrustedContact
      ? {
          firstName: openJointInvestAccountTrustedContact.firstName,
          lastName: openJointInvestAccountTrustedContact.lastName,
          email: openJointInvestAccountTrustedContact.email ?? null,
          phoneNumber: openJointInvestAccountTrustedContact.phoneNumber ?? null,
        }
      : null,
  };
};

export const Confirmation = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { addToast } = useToast();

  const forms = useSelector((state) => state.reactHookForm);
  const { fullyPaidLendingStatus } = useSelector(
    (state: AppState) => state.newFlows.INVEST_ONBOARDING,
  );

  const {
    data: requiredDocumentsResponse,
    loading: isRequiredDocumentsLoading,
    error,
  } = useInvestAccountReviewStepRequiredDocumentsQuery({
    variables: {
      accountRegistration: 'JOINT',
    },
  });

  const [openAccount, { loading: isMutationLoading }] = useOpenAccountMutation({
    variables: {
      input: {
        ...prepareOpenAccountInput(forms),
        signature:
          requiredDocumentsResponse?.viewer.invest?.requiredDocuments.signature,
      },
    },
  });

  const [updateFullyPaidLendingStatus] =
    useUpdateFullyPaidLendingStatusMutation({
      variables: {
        input: {
          fplStatus: fullyPaidLendingStatus,
        },
      },
    });
  const wiz = useOpenInvestJointAccountWizardContext();

  if (isRequiredDocumentsLoading) {
    return <Spinner fullScreen />;
  }

  if (error || !requiredDocumentsResponse?.viewer.invest) {
    return <GenericSystemError />;
  }

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={64} pb={32}>
        Does everything look correct?
      </HM>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <HXXS content="Secondary Account Holder" />
        </Flex>
        <Button
          kind="link"
          label="Edit"
          onClick={() => {
            wiz.goTo('CONTACT_INFO');
          }}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Full Name" />
        <PL
          content={buildFullName({
            firstName: forms.openJointInvestAccountContactInfo.firstName,
            middleInitial:
              forms.openJointInvestAccountContactInfo.middleInitial,
            suffix: forms.openJointInvestAccountContactInfo.suffix,
            lastName: forms.openJointInvestAccountContactInfo.lastName,
          })}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Address" />
        <PL
          content={buildAddress(
            forms.openJointInvestAccountContactInfo.homeAddress,
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Phone" />
        <PL
          content={formatPhoneNumber(
            forms.openJointInvestAccountContactInfo.phoneNumber,
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Employment" />
        <PL
          content={getEnumDescription(
            forms.openJointInvestAccountEmployment.employmentStatus,
            'EmploymentStatusEnum',
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Birthday" />
        <PL
          content={moment(
            forms.openJointInvestAccountIdentity.dateOfBirth,
          ).format('ll')}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="SSN" />
        <PL
          content={maskSsn(
            forms.openJointInvestAccountSocialSecurityNumber.socialSecurityNumber.replaceAll(
              '-',
              '',
            ),
          )}
        />
      </Flex>
      {forms.openJointInvestAccountTrustedContact && (
        <Flex justifyContent="space-between" pt={8}>
          <PL content="Trusted Contact" />
          <Flex flexDirection="column">
            {formatTrustedContactAsAnArray(
              forms.openJointInvestAccountTrustedContact,
            ).map((s) => (
              <PL content={s} key={s} textAlign="right" />
            ))}
          </Flex>
        </Flex>
      )}
      <Divider spacing="relaxed" />
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <HXXS content="Financial & Investment Profile" />
        </Flex>
        <Button
          kind="link"
          label="Edit"
          onClick={() => {
            wiz.goTo('ANNUAL_INCOME');
          }}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Income" />
        <PL content={forms.openJointInvestAccountAnnualIncome.annualIncome} />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Net Worth" />
        <PL
          content={getEnumDescription(
            forms.openJointInvestAccountNetWorth.suitability.totalNetWorth,
            'TotalNetWorthEnum',
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Liquid Net Worth" />
        <PL
          content={getEnumDescription(
            forms.openJointInvestAccountLiquidNetWorth.liquidNetWorth,
            'LiquidNetWorthEnum',
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Liquidity Needs" />
        <PL
          content={getEnumDescription(
            forms.openJointInvestAccountLiquidityNeeds.liquidityNeeds,
            'LiquidityNeedsEnum',
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Risk Tolerance" />
        <PL
          content={getEnumDescription(
            forms.openJointInvestAccountRiskTolerance.riskTolerance,
            'RiskToleranceEnum',
          )}
        />
      </Flex>
      <Flex justifyContent="space-between" pt={8}>
        <PL content="Time Horizon" />
        <PL
          content={getEnumDescription(
            forms.openJointInvestAccountTimeHorizon.timeHorizon,
            'TimeHorizonEnum',
          )}
        />
      </Flex>
      <Divider spacing="relaxed" />
      <Box>
        <PL
          content={
            requiredDocumentsResponse?.viewer.invest?.requiredDocuments.preamble
          }
          mb={16}
          color="foregroundNeutralMain"
        />
        <Flex
          flexDirection="column"
          flexWrap="wrap"
          minHeight={288}
          maxHeight={320}
          marginBottom={16}
        >
          {requiredDocumentsResponse?.viewer.invest?.requiredDocuments.documents.map(
            (d) => (
              <Box key={d.url} pb={8} maxWidth="50%">
                <Link to={d.url} target="_blank">
                  {d.title}
                </Link>
              </Box>
            ),
          )}
        </Flex>
        <PL
          content={
            requiredDocumentsResponse?.viewer.invest?.requiredDocuments
              .postamble
          }
        />
      </Box>
      <Divider spacing="relaxed" />
      <InvestFullyPaidLendingDisclosure accountRegistration="JOINT" />

      <Box mb={64}>
        <Button
          disabled={isMutationLoading}
          label="Continue"
          size="large"
          onClick={async () => {
            dispatch(showLoadingSpinner());
            updateFullyPaidLendingStatus();
            await openAccount({
              onCompleted: (data) => {
                dispatch(hideLoadingSpinner());

                const accountId = data?.openAccount?.outcome?.account?.id;

                if (!accountId) {
                  addToast({
                    kind: 'alert',
                    content:
                      'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
                  });
                } else {
                  dispatch({
                    type: 'SET_ACTIVE_INVEST_ACCOUNT',
                    payload: accountId,
                  });

                  dispatch(resetAllReactHookFormData());

                  navigate({ to: '/d/invest/portfolio' });

                  addToast({
                    kind: 'success',
                    content: 'Success! Your account was successfully created.',
                  });
                }
              },
              onError: () => {
                dispatch(hideLoadingSpinner());

                addToast({
                  kind: 'alert',
                  content:
                    'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
                });
              },
            });
          }}
        />
      </Box>
    </Flex>
  );
};
