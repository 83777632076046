import { Button, Flex, HM, HXXS, Card } from '@m1/liquid-react';
import memoize from 'lodash-es/memoize';
import * as React from 'react';
import { FormSection, type InjectedFormProps } from 'redux-form';

import { TransferRequirementsTimingDescriptionCell } from '~/components/transfer-requirements';
import {
  TransferAmountField,
  TransferParticipantDropdownField,
  ScheduleFrequencyFields,
} from '~/forms/fields';
import { required } from '~/forms/validators';
import type { SavingsFundAccountQuery } from '~/graphql/types';
import { connectForm } from '~/hocs';
import { clickedSwitchModes } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import {
  TRANSFER_SCHEDULE_FREQUENCIES,
  CREATE_TRANSFER_FLOW_MODES as MODES,
} from '~/static-constants';
import type { DropdownGroup } from '~/toolbox/Dropdown';

type Transfers = SavingsFundAccountQuery['viewer']['transfers'];

type SavingsInitialDepositProps = InjectedFormProps & {
  handleSkip: () => void;
  transfers: Transfers;
  isSchedule: boolean;
  onHandleSubmit: (...args: Array<any>) => any;
  toParticipantDropdownOptions: Array<DropdownGroup>;
  showTransactionError: boolean;
  onFromParticipantChange: (id: string) => void;
};

type ScheduleFieldProps = {
  transfers: Transfers;
};

const readParticipants = (transfers: Transfers): Array<DropdownGroup> => {
  const sources = new Map();
  const list = transfers?.sourceParticipants?.list;
  if (!list) {
    return [];
  }
  for (const { id, transferParticipantType, transferParticipantName } of list) {
    const option = {
      label: transferParticipantName,
      value: id,
    };
    const participantTypeLabel =
      transferParticipantType === 'SAVE' ? 'EARN' : transferParticipantType;
    const options = sources.get(participantTypeLabel);
    if (options) {
      sources.set(participantTypeLabel, [...options, option]);
    } else {
      sources.set(participantTypeLabel, [option]);
    }
  }

  return Array.from(sources, ([label, options]) => ({
    label,
    options,
  }));
};

const readSourceParticipants = memoize((transfers: Transfers) => {
  if (
    transfers &&
    transfers.sourceParticipants &&
    Array.isArray(transfers.sourceParticipants.list)
  ) {
    return readParticipants(transfers);
  }

  return null;
});

const ScheduleFields = ({ transfers }: ScheduleFieldProps) => {
  return (
    <FormSection name="schedule">
      <HXXS content="Schedule" mt={16} />
      <ScheduleFrequencyFields
        initialFrequency={TRANSFER_SCHEDULE_FREQUENCIES.MONTHLY}
        isEvenWeek={transfers && transfers.isEvenWeek}
      />
    </FormSection>
  );
};

const SavingsInitialDepositFormComponent = ({
  handleSubmit,
  handleSkip,
  transfers,
  isSchedule,
  onHandleSubmit,
  toParticipantDropdownOptions,
  showTransactionError,
  onFromParticipantChange,
}: SavingsInitialDepositProps) => {
  const dispatch = useDispatch();
  const scheduleOrOneTimeContent = isSchedule
    ? 'Switch to a one-time transfer'
    : 'Switch to a recurring transfer';
  const amount = useSelector(
    (state) => state.form['savings-initial-deposit'].values.amount,
  );

  return (
    <Flex
      width="489px"
      flexDirection="column"
      margin="0 auto"
      alignItems="center"
    >
      <HM mt="84px" alignSelf="start" content="Make an initial deposit" />
      <Card mt="48px" p="32px" width="100%">
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <TransferParticipantDropdownField
            label="From"
            name="fromParticipantId"
            options={readSourceParticipants(transfers)}
            onChange={(id: string) => onFromParticipantChange(id)}
          />
          <TransferParticipantDropdownField
            label="To"
            name="toParticipantId"
            options={toParticipantDropdownOptions}
            style={{
              marginTop: 16,
            }}
            disabled
          />
          <TransferAmountField
            autoFocus
            name="amount"
            label="Deposit amount"
            nonSubmissionError={showTransactionError}
            validate={[required]}
          />
          <Button
            kind="link"
            label={scheduleOrOneTimeContent}
            style={{ fontSize: '16px', marginTop: '24px' }}
            onClick={() =>
              dispatch(
                clickedSwitchModes(
                  isSchedule ? MODES.ONE_TIME : MODES.SCHEDULE,
                ),
              )
            }
          />
          {isSchedule && <ScheduleFields transfers={transfers} />}
          {!isSchedule && amount && (
            <TransferRequirementsTimingDescriptionCell
              dependsOnHolds={transfers?.requirements?.dependsOnHolds}
              dependsOnSells={transfers?.requirements?.dependsOnSells}
              timingDescription={transfers?.requirements?.timingDescription}
            />
          )}
          <Flex flexDirection="column" alignItems="center">
            <Button
              mt={48}
              kind="primary"
              size="large"
              label="Confirm deposit"
              type="submit"
            />
            <Button
              kind="link"
              style={{ fontSize: '16px', marginTop: '24px' }}
              label="Skip, I'll do this later"
              onClick={handleSkip}
            />
          </Flex>
        </form>
      </Card>
    </Flex>
  );
};

const enhancer = connectForm({
  form: 'savings-initial-deposit',
});

export const SavingsInitialDepositForm = enhancer(
  SavingsInitialDepositFormComponent,
) as any;
