import { Box, Flex, PS, useSkeleton } from '@m1/liquid-react';
import moment from 'moment';
import React from 'react';

import { SliceableNameCell } from '~/components/pie';
import { anUpcomingDividend, aSecurity } from '~/graphql/fixtures';
import type { DividendTableUpcomingDividendFragment } from '~/graphql/types';
import { useSortTable } from '~/hooks/useSortTable';
import { AppPill } from '~/lens-toolbox/AppPill';
import { GridTable } from '~/toolbox/grid-table';
import {
  type VirtualRowProps,
  VirtualizedRows,
} from '~/toolbox/virtualized/VirtualizedRows';
import { CashFormatter } from '~/utils';
import { parseCurrency } from '~/utils/parseCurrency';

import { DividendPerShareTooltip } from './tooltips/DividendPerShareTooltip';
import { SharesTooltip } from './tooltips/SharesTooltip';

const ROW_HEIGHT = 64;
const MAX_ROW_COUNT = 7;

type UpcomingDividendsTableProps = {
  upcomingDividends?: DividendTableUpcomingDividendFragment[];
};

const makeMockDividened = (symbol: string) =>
  anUpcomingDividend({
    security: aSecurity({ symbol }),
  }) as DividendTableUpcomingDividendFragment;

const mockedUpcomingDividends = [
  makeMockDividened('A'),
  makeMockDividened('B'),
  makeMockDividened('C'),
  makeMockDividened('D'),
  makeMockDividened('E'),
  makeMockDividened('F'),
  makeMockDividened('G'),
  makeMockDividened('H'),
];

const getSortableDate = (date: string | undefined | null) => {
  if (!date) {
    return '--';
  }
  return moment(date, [
    'MMM D, YYYY', // used when the dividend date is confirmed; dividend may be paid or estimated
    'MMM YYYY', // used for estimated dividends when the dividend date is unknown
  ]).format('YYYY-MM-DD');
};

export const UpcomingDividendsTable = ({
  upcomingDividends,
}: UpcomingDividendsTableProps) => {
  const { isLoading } = useSkeleton();
  const list = React.useMemo(
    () => (isLoading ? mockedUpcomingDividends : (upcomingDividends ?? [])),
    [isLoading, upcomingDividends],
  );
  const { createHeaderSortProps, sortedList } = useSortTable({
    list,
    defaults: {
      direction: 'ASC',
      path: 'payDate',
      getValue: (upcomingDividend) => getSortableDate(upcomingDividend.payDate),
    },
  });

  return (
    <Box>
      <GridTable
        emptyMessage="Pending and estimated future dividends will be listed here."
        gridTemplateColumns="minmax(200px, 3fr) repeat(5, 130px) 100px"
        css={{
          padding: 0,
          margin: 0,
        }}
        isNavigable={false}
        virtualization={{
          visibleRowCount: MAX_ROW_COUNT,
          rowList: sortedList,
        }}
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell
            {...{
              label: 'Name',
              ...createHeaderSortProps({ path: 'security.symbol' }),
            }}
          />
          <GridTable.HeaderCell
            {...{
              justifySelf: 'right',
              label: 'Ex-dividend date',
              ...createHeaderSortProps({
                path: 'exDividendDate',
                getValue: (upcomingDividend) =>
                  getSortableDate(upcomingDividend.exDividendDate),
              }),
            }}
          />
          <GridTable.HeaderCell
            {...{
              justifySelf: 'right',
              label: 'Pay date',
              ...createHeaderSortProps({
                path: 'payDate',
                getValue: (upcomingDividend) =>
                  getSortableDate(upcomingDividend.payDate),
              }),
            }}
          />
          <GridTable.HeaderCell
            {...{
              justifySelf: 'right',
              label: (
                <Flex>
                  <PS content="Shares" />
                  <SharesTooltip />
                </Flex>
              ),
              ...createHeaderSortProps({ path: 'numOfShares' }),
            }}
          />
          <GridTable.HeaderCell
            {...{
              justifySelf: 'right',
              label: (
                <Flex>
                  <PS content="Dividend per share" />
                  <DividendPerShareTooltip />
                </Flex>
              ),
              ...createHeaderSortProps({ path: 'dividendPerShare' }),
            }}
          />
          <GridTable.HeaderCell
            {...{
              justifySelf: 'right',
              label: 'Payment amount',
              ...createHeaderSortProps({
                path: 'payAmount',
                getValue: (upcomingDividend) =>
                  upcomingDividend.paymentAmount
                    ? parseCurrency(upcomingDividend.paymentAmount)
                    : 0,
              }),
            }}
          />
          <GridTable.HeaderCell
            {...{
              justifySelf: 'right',
              label: 'Status',
              ...createHeaderSortProps({ path: 'dividendStatus.label' }),
              style: { paddingRight: 0 },
            }}
          />
        </GridTable.HeaderRow>
        {/* The below row spans the full width of the table, to allow for a virtualized "window" */}
        <VirtualizedRows
          {...{
            rowHeight: ROW_HEIGHT,
            row: UpcomingDividendRow,
          }}
        />
      </GridTable>
    </Box>
  );
};

export const UpcomingDividendRow: React.FC<
  VirtualRowProps<DividendTableUpcomingDividendFragment>
> = ({ index, style, data }) => {
  const upcomingDividend = data[index];
  const {
    security,
    exDividendDate,
    payDate,
    numOfShares,
    dividendPerShare,
    paymentAmount,
    dividendStatus,
  } = upcomingDividend;

  return (
    <GridTable.Row
      key={`upcoming-dividend-${security?.symbol}-${exDividendDate}-${numOfShares}`}
      usesCaretLeftIndicator={false}
      css={{
        ...style,
        borderLeftWidth: '1px',
      }}
    >
      <GridTable.Cell>
        <SliceableNameCell sliceable={upcomingDividend?.security} truncated />
      </GridTable.Cell>
      <GridTable.Cell justifySelf="right">
        {exDividendDate ?? '--'}
      </GridTable.Cell>
      <GridTable.Cell justifySelf="right">{payDate ?? '--'}</GridTable.Cell>
      <GridTable.Cell justifySelf="right">{numOfShares ?? '--'}</GridTable.Cell>
      <GridTable.Cell justifySelf="right">
        {dividendPerShare || dividendPerShare === 0
          ? CashFormatter.format(dividendPerShare)
          : '$--'}
      </GridTable.Cell>
      <GridTable.Cell justifySelf="right">
        {paymentAmount ?? '$--'}
      </GridTable.Cell>
      <GridTable.Cell justifySelf="right">
        {dividendStatus ? <AppPill appPill={dividendStatus} /> : '--'}
      </GridTable.Cell>
    </GridTable.Row>
  );
};
