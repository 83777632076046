import * as React from 'react';

import type { WizardContextValue } from './types';

export const WizardProviderContext =
  React.createContext<WizardContextValue<any> | null>(null);

export const useWizardContext = <K extends string>(): WizardContextValue<K> => {
  const wizardContext = React.useContext(WizardProviderContext);
  if (!wizardContext) {
    throw new Error('You must use useWizardContext within WizardProvider.');
  }
  return wizardContext;
};
