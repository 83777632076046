import { Box, Button, Flex, HXXS } from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';
import {
  type FieldValues,
  FormProvider,
  type SubmitHandler,
  useForm,
  useFormContext,
  type UseFormReturn,
} from 'react-hook-form';

import { AddressFieldsSection } from '~/components/form/address/AddressFieldsSection';
import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useProfileReviewQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import type { AccountProfilesFragment } from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

import { ProfileUpdateBanner } from '../components/ProfileUpdateBanner';

import { UpdateEmail } from './UpdateEmail';

type AccountHolderFormProps<T extends FieldValues> = {
  accountType: 'primary' | 'secondary';
  onSubmit: SubmitHandler<T>;
  formMethods: UseFormReturn<T>;
  disabled?: boolean;
};

const AccountHolderForm = <Values extends FieldValues>({
  accountType,
  onSubmit,
  formMethods,
  disabled,
}: AccountHolderFormProps<Values>) => {
  const { control } = useFormContext();

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Flex flexDirection="column">
        <Box pb={16}>
          <HXXS>{`${capitalize(accountType)} account holder`}</HXXS>
        </Box>
        <Flex flexDirection="row">
          <Box width="30%" flexGrow={1}>
            <ControlledInput
              label="First name"
              name={`${accountType}.firstName`}
              disabled
              control={control}
            />
          </Box>
          <Box mx={10} width="calc(20% - 20px)" flexShrink={1}>
            <ControlledInput
              name={`${accountType}.middleInitial`}
              placeholder="M.I. (Opt)"
              disabled
              control={control}
            />
          </Box>
          <Box width="50%" flexGrow={1}>
            <ControlledInput
              label="Last name"
              name={`${accountType}.lastName`}
              disabled
              control={control}
            />
          </Box>
        </Flex>
        <AddressFieldsSection<`${typeof accountType}.homeAddress`>
          kind="NORMAL"
          disabled={disabled}
          namespace={`${accountType}.homeAddress`}
          modernLayout
        />
        <Flex flexDirection="row">
          <Box width="50%" paddingRight="20px">
            <ControlledInput
              label="Phone number"
              name={`${accountType}.phoneNumber`}
              disabled
              control={control}
            />
          </Box>
        </Flex>
        <Box alignSelf="flex-end">
          <Button type="submit" disabled={disabled}>
            Save
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

export const ContactInformationPage = () => {
  const { addToast } = useToast();
  const formMethods = useForm<AccountProfilesFragment>({
    defaultValues: {},
  });

  const { data, loading } = useProfileReviewQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [updateProfileMutation] = useUpdateProfileMutation();

  React.useEffect(() => {
    const primary = data?.viewer.profile?.primary;
    const secondary = data?.viewer.profile?.secondary;

    if (primary) {
      formMethods.reset({
        primary: primary,
        ...(secondary ? { secondary: secondary } : {}),
      });
    }
  }, [data, formMethods]);

  const handleSubmitFor =
    (account: 'primary' | 'secondary') =>
    (updates: AccountProfilesFragment) => {
      updateProfileMutation({
        variables: {
          input: {
            profile: {
              primary: withoutTypename({
                ...data?.viewer.profile?.primary,
                ...(account === 'primary' ? updates.primary : {}),
              }),
              // only include secondary if it existed in initial query
              ...(data?.viewer.profile?.secondary
                ? {
                    secondary: withoutTypename({
                      ...data?.viewer.profile?.secondary,
                      ...(account === 'secondary' ? updates.secondary : {}),
                    }),
                  }
                : {}),
            },
          },
        },
        onCompleted: () => {
          addToast({
            content: 'Profile Updated',
            kind: 'success',
            duration: 'long',
          });
        },
        onError: () => {
          addToast({
            content: 'Error updating profile. Please try again later.',
            kind: 'alert',
            duration: 'long',
          });
        },
      });
    };

  if (loading) {
    return <Spinner fullScreen />;
  }

  const viewer = data?.viewer;
  const profile = viewer?.profile;

  if (!profile) {
    return (
      <GenericSystemError content="Your profile is currently unavailable." />
    );
  }

  const username = viewer.user?.username;
  const hasSecondaryProfile = Boolean(profile.secondary);
  const canEditAddress = Boolean(profile.allowsHomeAddressUpdates);

  const isDisabled = !canEditAddress;

  return (
    <>
      <FormProvider {...formMethods}>
        <ProfileUpdateBanner profile={profile} />
        {username && (
          <UpdateEmail
            email={username}
            phoneNumber={profile.primary.phoneNumber}
          />
        )}

        <AccountHolderForm<AccountProfilesFragment>
          accountType="primary"
          onSubmit={handleSubmitFor('primary')}
          formMethods={formMethods}
          disabled={isDisabled}
        />
        {hasSecondaryProfile && (
          <>
            <Divider />
            <AccountHolderForm<AccountProfilesFragment>
              accountType="secondary"
              onSubmit={handleSubmitFor('secondary')}
              formMethods={formMethods}
              disabled={isDisabled}
            />
          </>
        )}
      </FormProvider>
    </>
  );
};
