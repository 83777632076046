import { Flex, PM } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import {
  TAX_LOTS_DOWNLOAD_DISCLAIMER,
  exportToCsv,
  prepareToxLotsForDownload,
} from '~/components/DownloadCSV/utils';
import { useAccountTaxLotsLazyQuery } from '~/graphql/hooks';
import type { AccountTaxLot, LotTypeEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSupportLink } from '~/hooks/useSupportLink';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

type TaxLotDownloadProps = {
  header: string;
  lotType: LotTypeEnum;
  setIsOpen: (isOpen: boolean) => void;
};

export const TaxLotDownload = ({
  header,
  lotType,
  setIsOpen,
}: TaxLotDownloadProps) => {
  const activeAccountId = useSelector((state) => state.global.activeAccountId);
  const supportLink = useSupportLink();
  const { addToast, currentToast } = useToast();
  const analytics = useAnalytics();
  const [storedTaxLots, setStoredTaxLots] = React.useState<AccountTaxLot[]>([]);
  const lotTypeText = lotType === 'OPEN' ? 'Open' : 'Closed';
  const [getTaxLots, { data, refetch }] = useAccountTaxLotsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: activeAccountId as string,
      lotType,
      first: 2000,
    },
    onError: (error) => {
      const formattedErrorMessage = error.message.split('(')[0];
      addToast({
        kind: 'alert',
        content: formattedErrorMessage,
        duration: 'long',
        link: {
          title: 'Contact us',
          ...supportLink,
        },
      });
    },
  });

  const handleClick = () => {
    setIsOpen(false);
    analytics.recordEvent('m1_invest_holdings_taxlots_downloaded', null, {
      lot_status: lotType.toLowerCase(),
    });

    // Get the tax lots data
    getTaxLots();

    if (!currentToast) {
      addToast({
        kind: 'informational',
        content: 'Preparing your file. This may take a few seconds.',
      });
    }
  };

  React.useEffect(() => {
    if (data && data?.node?.__typename === 'Account') {
      const currentTaxLots =
        data?.node?.taxLots?.edges?.map((edge) => edge?.node) || [];
      // push the current page of tax lots to local state.
      setStoredTaxLots(
        (prev) => [...prev, ...currentTaxLots] as AccountTaxLot[],
      );
      if (data?.node?.taxLots?.pageInfo?.hasNextPage) {
        // will continue to fetch until there are no more pages
        refetch({
          after: data?.node?.taxLots?.pageInfo?.endCursor,
        });
      } else {
        const totalTaxLots = [
          ...storedTaxLots,
          ...currentTaxLots,
        ] as AccountTaxLot[];
        if (totalTaxLots.length < 1) {
          addToast({
            kind: 'informational',
            content: `There is no ${lotType.toLowerCase()} tax lot data available to download at this time.`,
            duration: 'long',
          });
        } else {
          exportToCsv({
            filename:
              `${data.node.number}-${lotTypeText}-tax-lots ` +
              moment().format('MMM-DD-YYYY'),
            disclaimer: TAX_LOTS_DOWNLOAD_DISCLAIMER,
            headers: [
              'Symbol',
              'Cusip',
              'Acquisition Date',
              'Quantity',
              'Cost Basis',
              'Short/Long Term Holding',
              'Unrealized Gain/Loss',
              'Close Date',
              'Short Term Realized Gain/Loss',
              'Long Term Realized Gain/Loss',
              'Wash Sale Indicator',
              'M1 Tax Lot Id',
            ],
            rows: prepareToxLotsForDownload(totalTaxLots),
          });
          addToast({
            kind: 'success',
            content: 'Your file is now accessible in you downloads folder.',
            duration: 'long',
          });
          setStoredTaxLots([]);
        }
      }
    }
  }, [data, getTaxLots]);

  return (
    <Flex height={40} onClick={handleClick}>
      <PM color="foregroundNeutralMain" content={header} ml={16} />
    </Flex>
  );
};
