import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@m1/liquid-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { UNSAFE_connectRedux } from '~/hocs';
import type { AppState } from '~/redux';

import { AppContext, type AppContextType } from './AppContext';

type Props = AppContextType & {
  children?: React.ReactElement;
  isM1Employee: boolean | null | undefined;
};

class ProviderComponent extends React.Component<Props> {
  static childContextTypes = {
    auth: PropTypes.object,
    analytics: PropTypes.object,
    cdsSender: PropTypes.object,
    sentry: PropTypes.object,
    launchDarkly: PropTypes.object,
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }),
    apolloClient: PropTypes.object,
  };

  getChildContext(): AppContextType {
    return {
      auth: this.props.auth,
      analytics: this.props.analytics,
      cdsSender: this.props.cdsSender,
      launchDarkly: this.props.launchDarkly,
      sentry: this.props.sentry,
      store: this.props.store,
      apolloClient: this.props.apolloClient,
    };
  }

  render() {
    const { store, children } = this.props;
    const isProduction =
      __NODE_ENV__ === 'production' && __ENV__ === 'production';

    const context = this.getChildContext();

    return (
      <ThemeProvider isProduction={isProduction}>
        <AppContext.Provider value={context}>
          <ReduxProvider store={store}>
            <ApolloProvider client={context.apolloClient}>
              <IntercomProvider appId={window.config.intercom.appId}>
                {React.Children.only(children)}
              </IntercomProvider>
            </ApolloProvider>
          </ReduxProvider>
        </AppContext.Provider>
      </ThemeProvider>
    );
  }
}

const enhancer = UNSAFE_connectRedux((state: AppState) => ({
  isM1Employee: state.global.isM1Employee,
}));

export const Provider = enhancer(ProviderComponent) as any;
