import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { CryptoDisclosure } from '~/components/CryptoDisclosure';
import type {
  Account,
  TransferWizardParticipantDetailsFragment,
} from '~/graphql/types';

const StyledDisclosureContainer = styled(Box)`
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.backgroundInfoSubtle};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borderInfo};
  margin-bottom: 32px;
  font: ${({ theme }) => theme.typography.PM};
`;

export const TransferCryptoDisclosure = ({
  sourceDetails,
  destinationDetails,
}: {
  sourceDetails: TransferWizardParticipantDetailsFragment | null;
  destinationDetails: TransferWizardParticipantDetailsFragment | null;
}) => {
  const isCryptoTransfer =
    (sourceDetails?.account as Account)?.isCryptoAccount ||
    (destinationDetails?.account as Account)?.isCryptoAccount;

  if (!isCryptoTransfer) {
    return null;
  }

  return (
    <StyledDisclosureContainer>
      <CryptoDisclosure />
    </StyledDisclosureContainer>
  );
};
