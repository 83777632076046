import React from 'react';

import type {
  SecurityDetail_Equity_Fragment,
  SecurityDetail_Fund_Fragment,
} from '~/graphql/types';
import { formatNumberWithCommas, getEnumDescription } from '~/utils';

import { SecurityDataRow } from '../SecurityDataRow';

type EquityProfileDataProps = {
  security: SecurityDetail_Equity_Fragment | SecurityDetail_Fund_Fragment;
};

export const EquityProfileData = ({ security }: EquityProfileDataProps) => {
  const { profile, market, fundamentals } = security;
  const formatExchange = (exchange: string): string => {
    const parts = exchange.split(' ');
    if (parts.length === 1) {
      return exchange;
    }

    let stringBuilder = '';
    parts.forEach((p: string): string => {
      if (p === 'NYSE' || p === 'NASDAQ') {
        stringBuilder += `${p} `;
        return stringBuilder;
      }
      const part = p.toLowerCase();
      stringBuilder += `${part.charAt(0).toUpperCase()}${part.slice(1)} `;
      return stringBuilder;
    });
    return stringBuilder.slice(0, stringBuilder.length - 1);
  };
  return (
    <>
      {profile?.ceo && (
        <SecurityDataRow headingContent="CEO" value={profile.ceo} />
      )}
      {profile?.numberOfEmployees && (
        <SecurityDataRow
          headingContent="Employees"
          value={formatNumberWithCommas(profile.numberOfEmployees)}
        />
      )}
      <SecurityDataRow
        headingContent="Headquarters"
        value={profile?.headquarters}
      />
      {market && (
        <SecurityDataRow
          headingContent="Exchange"
          value={formatExchange(market)}
        />
      )}
      <SecurityDataRow
        headingContent="Shares outstanding"
        value={fundamentals?.sharesOutstandingShort}
      />
      <SecurityDataRow
        headingContent="Issue type"
        value={
          profile?.issueType &&
          getEnumDescription(profile.issueType, 'IssueTypeEnum')
        }
      />
    </>
  );
};
