import {
  Box,
  Flex,
  type LayoutableProps,
  PL,
  PM,
  spacingUnits,
  useTheme,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { components } from 'react-select';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { isNil, isNotNil } from '~/utils';

import { CreateTransferContext } from '../../CreateTransferContext';
import type { UseParticipantSummaryResult } from '../../utils/useParticipantSummary';

import {
  StyledContent,
  StyledLabel,
  StyledText,
} from './TransferParticipantFields.styled';
import {
  participantsToDropdownOptions,
  sortDropdownOptions,
} from './TransferParticipantFields.utils';
import { summaryToParticipantsList } from './utils';

export const GroupHeading = ({ children, ...rest }: any) => {
  return (
    <components.GroupHeading {...rest}>
      <PM color="foregroundNeutralSecondary" content={children} />
    </components.GroupHeading>
  );
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectOption = components.Option as any;
export const Option = (props: any) => {
  const { children, ...rest } = props;
  return (
    <ReactSelectOption {...rest}>
      <StyledContent
        isFocused={props.isFocused}
        selectProps={props.selectProps}
      >
        {isNotNil(props.selectProps.optionContent) ? (
          props.selectProps.optionContent(props as any)
        ) : (
          <StyledText
            content={children}
            isFocused={props.isFocused}
            selectProps={props.selectProps}
          />
        )}
        {props.isSelected === true && (
          <Icon name="check24" color="foregroundNeutralMain" ml={8} />
        )}
      </StyledContent>
    </ReactSelectOption>
  );
};

function Placeholder(props: any) {
  const isDisabled = props.selectProps.isDisabled;
  return (
    <components.Placeholder {...props}>
      <Flex alignItems="center" gap={16}>
        <Icon
          name={
            isDisabled === true
              ? 'accountAccountsDisabled32'
              : 'accountAccountsPrimary32'
          }
        />
        <PL
          color={
            isDisabled === true
              ? 'foregroundNeutralTertiary'
              : 'foregroundNeutralMain'
          }
          content="Select account"
        />
      </Flex>
    </components.Placeholder>
  );
}

const SelectContainer = (props: any) => {
  return (
    <components.SelectContainer {...props}>
      <Box position="relative" pt={8}>
        {isNotNil(props.selectProps.label) && (
          <StyledLabel
            htmlFor={props.selectProps.name}
            appTheme={props.selectProps.appTheme}
            backgroundColor={props.selectProps.backgroundColor}
            error={props.selectProps.error}
            isLabelForDestination={props.selectProps.name === 'destinationId'}
            isDisabled={props.selectProps.isDisabled}
            isFocused={props.selectProps.isFocused}
          >
            {props.selectProps.label}
          </StyledLabel>
        )}
        {props.children}
      </Box>
    </components.SelectContainer>
  );
};

export type TransferParticipantFieldsProps = LayoutableProps & {
  participantSummary: UseParticipantSummaryResult;
};

export const TransferParticipantFields = ({
  participantSummary,
}: TransferParticipantFieldsProps) => {
  const { pivot, isSelectedRelationshipEligible } = participantSummary;
  const theme = useTheme();
  const { control, setValue, trigger, watch } = useFormContext();
  const transferContext = React.useContext(CreateTransferContext);
  const { isDirty } = useFormState();

  const [sourceId, destinationId, schedule] = watch([
    'sourceId',
    'destinationId',
    'schedule',
  ]);

  // Source accounts (ie: "From" accounts) available
  const sourceParticipantsList = summaryToParticipantsList({
    participantSummary,
    side: 'FROM',
  });
  const sourceOptions = participantsToDropdownOptions(sourceParticipantsList);

  // Need to sort values so that "External Accounts" are first.
  const sortedSourceOptions = React.useMemo(
    () => sortDropdownOptions(sourceOptions),
    [sourceOptions],
  );

  // All destinations (ie: "To" accounts) available
  const destinationParticipantsList = summaryToParticipantsList({
    participantSummary,
    side: 'TO',
  });
  const destinationOptions = participantsToDropdownOptions(
    destinationParticipantsList,
  );

  // Need to sort values so that "External Accounts" are first.
  const sortedDestinationOptions = React.useMemo(
    () => sortDropdownOptions(destinationOptions),
    [destinationOptions],
  );

  React.useEffect(() => {
    if (!isDirty) {
      return;
    }
    /*
     * Reset the participant opposite pivot only when the pivot
     * participant isn't selected and the form is dirty.
     */
    if (isSelectedRelationshipEligible === false) {
      const pivotId = pivot === 'FROM' ? 'destinationId' : 'sourceId';
      setValue(pivotId, null);
    }
    /*
     * This will be triggered when the relationship is valid and the
     * schedule has changed.  This is important, since min/max values
     * vary between one-time and scheduled relationships.
     */
    trigger('amount');
  }, [
    isDirty,
    isSelectedRelationshipEligible,
    schedule,
    setValue,
    trigger,
    pivot,
  ]);

  const commonStyles = {
    control: {
      borderRadius: '8px 8px 0 0px',
      maxHeight: 'auto',
      minHeight: '72px',
      padding: '0 4px 0 0',
    },
    groupHeading: {
      borderTop: 'none',
      borderBottom: 'none',
      marginBottom: '8px',
      marginTop: '16px',
      padding: '0 24px',
      textTransform: 'inherit',
    },
    margin: 0,
    menu: {
      boxShadow:
        '0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 15px 40px 0px rgba(0, 0, 0, 0.20);',
    },
    pointerEvents: 'inherit',
  };
  const isIraAction =
    transferContext?.scenarioQueryParam === 'IRA_CONVERSION' ||
    transferContext?.scenarioQueryParam === 'IRA_RECHARACTERIZATION';
  const sourceIsDisabled =
    (isNil(destinationId) && pivot === 'TO') ||
    (isIraAction && pivot === 'FROM');

  const destinationIsDisabled =
    (isNil(sourceId) && pivot === 'FROM') || (isIraAction && pivot === 'TO');

  return (
    <Box mb={spacingUnits.l}>
      <ControlledDropdown
        components={{ GroupHeading, Option, Placeholder, SelectContainer }}
        control={control}
        disabled={sourceIsDisabled}
        isSearchable={false}
        label="From"
        name="sourceId"
        placeholder="Select"
        // Need to sort values so that "External Accounts" are first. This is required because there
        // is no guaranteed order to Object.values resolves the order from keys.
        // (see https://stackoverflow.com/a/5525820 for more info)
        source={sortedSourceOptions}
        style={{
          ...commonStyles,
          control: {
            ...commonStyles.control,
            borderRadius: '8px 8px 0 0',
            ...(sourceIsDisabled && {
              cursor: 'not-allowed',
              '&:hover': {
                borderColor: theme.colors.borderMain,
              },
            }),
          },
        }}
        rules={{ required: 'An account must be selected.' }}
      />
      <ControlledDropdown
        components={{ GroupHeading, Option, Placeholder, SelectContainer }}
        control={control}
        disabled={destinationIsDisabled}
        isSearchable={false}
        label="To"
        name="destinationId"
        placeholder="Select"
        source={sortedDestinationOptions}
        style={{
          ...commonStyles,
          marginTop: '-9px',
          control: {
            ...commonStyles.control,
            borderRadius: '0 0 8px 8px',
            ...(destinationIsDisabled && {
              cursor: 'not-allowed',
              '&:hover': {
                borderColor: theme.colors.borderMain,
              },
            }),
          },
        }}
        rules={{ required: 'An account must be selected.' }}
      />
    </Box>
  );
};
